import Vue from 'vue'

import { scrollUnlock, scrollLock } from '@utils/scroll'

Vue.directive('sidebar-content', {
  bind: node => {
    const toggler = node.querySelector('.js-content-sidebar-toggler')
    const sidebar = node.querySelector('.js-content-sidebar')
    const blur = node.querySelector('.js-content-sidebar-blur')
    const closeItems = [...node.querySelectorAll('.js-content-sidebar-close')]

    toggler.addEventListener('click', () => {
      sidebar.classList.toggle('is-active')
      blur.classList.toggle('is-active')

      scrollLock()
    })

    blur.addEventListener('click', () => {
      sidebar.classList.remove('is-active')
      blur.classList.remove('is-active')

      scrollUnlock()
    })

    closeItems.forEach(item => {
      item.addEventListener('click', () => {
        sidebar.classList.remove('is-active')
        blur.classList.remove('is-active')
        
        scrollUnlock()
      })
    })
  },
})
