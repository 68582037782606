export function sliderSnap() {
  const sliders = [...document.querySelectorAll('.js-slider-snap')]

  if (!sliders.length) return

  const AUTOPLAY_DURATION = 6000

  sliders.forEach(slider => {
    let isFirstRender = true

    const track = slider.querySelector('.js-slider-snap-track')
    const prev = slider.querySelector('.js-slider-snap-prev')
    const next = slider.querySelector('.js-slider-snap-next')
    const dots = [...slider.querySelectorAll('.js-slider-snap-dot')]
    const slides = slider.querySelectorAll('.js-slide-snap-slide')

    let autoplay = null
    let currentAutoplayPercent = 0

    // Slide transition
    const getNewScrollPosition = arg => {
      const maxScrollLeft = track.scrollWidth - track.clientWidth

      if ('forward' === arg) {
        const x = track.scrollLeft + track.clientWidth

        return x <= maxScrollLeft ? x : 0
      }

      if ('backward' === arg) {
        const x = track.scrollLeft - track.clientWidth

        return 0 <= x ? x : maxScrollLeft
      }

      if ('number' === typeof arg) {
        const x = arg * track.clientWidth

        return x
      }

      return 0
    }

    const navigate = direction => {
      track.scrollLeft = getNewScrollPosition(direction)

      currentAutoplayPercent = 0
    }

    const checkArrowsState = () => {
      const trackScrollWidth = track.scrollWidth
      const trackOuterWidth = track.clientWidth

      prev.removeAttribute('disabled')
      next.removeAttribute('disabled')

      if (0 >= track.scrollLeft) {
        prev.setAttribute('disabled', '')
      }

      if (track.scrollLeft === trackScrollWidth - trackOuterWidth) {
        next.setAttribute('disabled', '')
      }
    }

    checkArrowsState()

    track.addEventListener('scroll', () => {
      checkArrowsState()
    })

    prev.addEventListener('click', () => {
      navigate('backward')
    })

    next.addEventListener('click', () => {
      navigate('forward')
    })

    const fillDots = step => {
      const activeDot = dots.find(d => d.classList.contains('is-active'))

      if (!activeDot) return

      const dotTrack = activeDot.querySelector('.js-slider-snap-dot-track')

      dotTrack.style.width = `${step}%`
    }

    const resetAutoplay = () => {
      currentAutoplayPercent = 0

      clearInterval(autoplay)
    }

    const initAutoplay = () => {
      const interval = 20
      const step = 100 / (AUTOPLAY_DURATION / interval)

      autoplay = setInterval(() => {
        currentAutoplayPercent += step

        fillDots(currentAutoplayPercent)

        if (currentAutoplayPercent >= 100) {
          navigate('forward')

          currentAutoplayPercent = 0
        }
      }, interval)
    }

    initAutoplay()

    slider.addEventListener('touchstart', () => {
      resetAutoplay()
    }, false)

    slider.addEventListener('touchend', () => {
      currentAutoplayPercent = 0

      initAutoplay()
    }, false)

    dots.forEach((dot, index) => {
      dot.addEventListener('click', () => {
        currentAutoplayPercent = 0

        navigate(index)

        resetAutoplay()

        initAutoplay()
      })
    })

    const slideObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const datasetIndex = Number(entry.target.dataset.slideindex)

        if (!isFirstRender) {
          entry.target.classList.remove('is-active')
          entry.target.classList.remove('without-animation')
        }

        if (datasetIndex !== 1 && isFirstRender) {
          entry.target.classList.remove('without-animation')
        }

        if (entry.isIntersecting) {
          entry.target.classList.add('is-active')

          const sliderSubstrate = entry.target.getAttribute('data-substrate')

          if (sliderSubstrate === 'is-dark') {
            slider.classList.add('is-dark')
            slider.classList.remove('is-light')
          } else {
            slider.classList.remove('is-dark')
            slider.classList.add('is-light')
          }

          const slideIndex = datasetIndex - 1

          dots.forEach(dot => {
            dot.classList.remove('is-active')

            dot.querySelector('.js-slider-snap-dot-track').style.width = '0%'
          })

          dots[slideIndex].classList.add('is-active')
        }
      })
      isFirstRender = false
    }, { root: track, threshold: 0.1 })

    slides.forEach(slide => {
      slideObserver.observe(slide)
    })

    slider.addEventListener('mouseenter', () => {
      clearInterval(autoplay)

      autoplay = null
    })

    slider.addEventListener('mouseleave', () => {
      if (!autoplay) {
        initAutoplay()
      }
    })
  })
}
