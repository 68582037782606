<template lang="pug">
  include ../../mixins
  validation-observer(
    tag="form"
    ref="observer"
    v-slot="{ errors }"
    @submit.prevent="validateBeforeSubmit()"
  )
    +b.g-row.--appearance_spaced.--space_xl
      +b.g-cell.g-cols.--12-xs
        d-control-textarea(
          v-model="formdata.content"
          rules="required"
          type="text"
          name="content"
          input-class="d-control-input--height_1"
          :input-label="labels.content"
        )
      +b.g-cell.g-cols.--12-xs.--6-sm
        d-control-input(
          rules="required|username"
          type="text"
          :input-label="labels.name"
          v-model="formdata.name"
          name="name"
        )
      +b.g-cell.g-cols.--12-xs.--6-sm
        d-control-input(
          :rules="{ required: formdata.notificationMail, email: true }"
          type="email"
          inputmode="email"
          :input-label="labels.email"
          v-model="formdata.email"
          name="email"
        )
      +b.g-cell.g-cols.--12-xs
        +b.g-row.--space_lg.--space_2xl-xl.--appearance_spaced.--align_center.--justify_between
          +b.g-cell.g-cols.--12-xs.--auto-md
            +b.LABEL.control-checkbox.--variant_flex
              +e.INPUT.element(
                v-model="formdata.notificationMail"
                type="checkbox"
                name="notificationMail"
              )
              +e.SPAN.label
              +e.content
                +b.P.ds-caption.--size_xs.--size_sm-lg.--color_grey {{ _("Повідомляти про відповіді на пошту") }}
          +b.g-cell.g-cols.--12-xs.--narrow-md
            +b.button-wrapper.--variant_3
              +b.BUTTON.control-button(
                @click.prevent="validateBeforeSubmit()"
                :disabled="isLoad"
              )
                +e.element
                  +e.SPAN.text {{ _("Надіслати") }}
</template>

<script>
import { mapState } from 'vuex'
import FormMixin from '@mixins/form.mixin.js'
import { reviewAnswerAddResource } from '@services/review.service.js'

export default {
  mixins: [FormMixin],

  props: {
    reviewId: {
      type: Number,
    },
  },

  data() {
    return {
      formdata: {
        name: '',
        email: '',
        content: '',
        notificationMail: false,
        review: this.reviewId,
      },
      labels: {
        content: this._('Ваш відгук або питання'),
        name: this._('Ваше ім`я'),
        email: this._('Ваш Email'),
      },
    }
  },

  computed: {
    ...mapState('user', ['user']),
  },

  watch: {
    user: {
      deep: true,
      immediate: true,
      handler(nval) {
        this.setUserInfo(nval)
      },
    },
  },

  methods: {
    setUserInfo(info) {
      this.formdata.email = info.email
      this.formdata.name = `${info.firstName} ${info.lastName}`
    },

    prepareFormdata() {
      const formdata = JSON.parse(JSON.stringify(this.formdata))

      return formdata
    },

    send() {
      const formdata = this.prepareFormdata()

      return reviewAnswerAddResource.execute({}, formdata).then(() => {
        this.$refs.observer.reset()

        this.setUserInfo(this.user)

        this.formdata.content = ''

        this.$notify({
          title: this._('Коментар успішно додано'),
          type: 'success',
        })
      })
    },
  },
}
</script>
