<script>
import Forbidden from '@app/Modals/Authentication/Forbidden'

export default {
  methods: {
    openForbiddenModal() {
      this.$modal.show(Forbidden, {
        info: {
          title: this._('Лайкати можуть лише авторизовані користувачі'),
        },
      }, {
        classes: 'vm-modal--size_2sm',
      })
    },
  },
  render() {
    return this.$scopedSlots.default({
      openForbiddenModal: this.openForbiddenModal,
    })
  },
}
</script>
