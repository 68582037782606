<script>
import Vue from 'vue'

function RenderComponent(template) {
  const renderer = Vue.compile(template)
  return {
    render: renderer.render,
    staticRenderFns: renderer.staticRenderFns,
  }
}

export default {
  props: ['result', 'dataRes', 'classRender'],

  data() {
    return {
      component: '',
    }
  },

  watch: {
    result: {
      handler(nval) {
        if (nval) {
          if ('object' === typeof nval) {
            this.component = nval.val
          } else {
            this.component = RenderComponent(`<div class="${this.classRender}">${nval}</div>`)
          }
        }
      },
      immediate: true,
    },
  },

  render(h) {
    if ('object' === typeof this.result) {
      if (this.dataRes) {
        return h(
          'component', { is: this.component, props: { dataResult: this.dataRes } }
        )
      }
      return h('div', this.$slots.render)
    }
    if (this.result) {
      return h(
        'component', {
          is: this.component,
          on: {
            update: event => {
              this.$emit('update', event)
            },
            'update:track': event => {
              this.$emit('update:track', event)
            },
          },
        }
      )
    }
    return h('div', this.$slots.render)
  },
}
</script>
