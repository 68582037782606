<template lang="pug">
include ../mixins
+b.modal.--variant_heading
  +b.close(@click="$emit('close')")
    icon-close
  +e.heading(:class="{ 'is-without-text': !info.text }")
    +b.P.ds-caption.--size_lg.--weight_semi-bold {{ info.title }}
  +e.content(v-if="info.text")
    +b.P.ds-caption.--size_2sm {{ info.text }}
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
    },
  },
}
</script>
