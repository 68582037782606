export const favorite = {
  namespaced: true,

  state: {
    favoriteInfo: {
      count: 0,
    },
    lastAction: {
      added: null,
      removed: null,
    },
  },

  mutations: {
    UPDATE_COUNTER(state, payload) {
      state.favoriteInfo.count = payload
    },

    SET_REMOVED_ID(state, payload) {
      state.lastAction.removed = payload
    },

    SET_ADDED_ID(state, payload) {
      state.lastAction.added = payload
    },
  },
}
