<template lang="pug">
  include ../mixins
  ui-dropdown(v-slot="{ isActive, toggle, hide }")
    ui-click-outside(:do="hide")
      +b.ui-dropdown
        +e.element(@click.prevent="toggle")
          +e.SPAN.single(v-if="currentLanguage") {{ currentLanguage.label }}
          +e.SPAN.icon(:class="{ 'is-active': isActive }")
            icon-chevron-down
        +e.wrapper(:class="{ 'is-active': isActive }")
          +e.A.item(
            v-for="lang in filteredLanguages"
            :href="getLanguageLink(lang.value)"
          )
            +e.SPAN.content {{ lang.label }}
</template>

<script>
import { CURRENT_LANGUAGE, prefixLanguage } from '@utils/urls'

export default {
  name: 'language-control',

  data() {
    return {
      languages: [
        // { value: 'en', label: this._('EN') },
        { value: 'ru', label: this._('RU') },
        { value: 'uk', label: this._('UA') },
      ],
    }
  },

  computed: {
    currentLanguage() {
      return this.languages.find(el => el.value === CURRENT_LANGUAGE)
    },

    filteredLanguages() {
      return this.languages.filter(el => el.value !== CURRENT_LANGUAGE)
    },
  },

  methods: {
    getLanguageLink(language) {
      return prefixLanguage(window.location.href, { language })
    },
  },
}
</script>
