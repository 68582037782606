import { routes as UserRoutes } from '@app/Cabinet/router'

const routes = [
  ...UserRoutes,
]

export function createRoutes() {
  return routes
}

export function createRouter({ Router, Vue }) {
  return new Router({
    mode: 'history',
    base: '/',
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      }

      return {
        x: window.scrollX,
        y: window.scrollY,
      }
    },
    routes: createRoutes({ Router, Vue }),
  })
}
