<template lang="pug">
  include ../../mixins
  div
    +b.ds-panel.--space_lg(v-if="choicesLocal.length > 20")
      +e.element.--offset_bottom
        d-control-input(
          v-model="searchValue",
          type="text"
          :placeholder="_('Поиск')"
          @input="searchAttrs"
        )
    ui-ripple(
      v-for="item in choicesLocal"
      :key="item.label"
    )
      +b.filter-option
        +b.LABEL.control-checkbox.--variant_flex
          +e.INPUT.element(
            v-model="checked"
            type="checkbox"
            :name="filter.label"
            :value="item.label"
            @change="submit"
          )
          +e.SPAN.label
          +e.content
            +b.P.ds-caption.--size_2xs.--size_sm-xl.--color_dark {{ item.title }}
</template>

<script>
export default {
  props: {
    filter: Object,
    value: [Object, Array],
  },

  data() {
    return {
      checked: [],
      searchValue: null,
      choicesLocal: [],
    }
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(nval) {
        this.checked = this.normalize(nval)
      },
    },

    filter: {
      deep: true,
      immediate: true,
      handler(nval) {
        if (nval && nval.options) {
          this.choicesLocal = nval.options
        }
      },
    },
  },

  methods: {
    submit() {
      this.$emit('input', this.checked)
    },

    normalize(value) {
      let val = value

      if (!value) return []

      if (!Array.isArray(value)) {
        val = [value]
      }

      if (this.filter.setInitialValue) {
        return val
      }

      const checked = this.filter.options
        .filter(el => val.find(v => (v).toString() === (el.label).toString())).map(el => el.label)

      return checked
    },

    searchAttrs() {
      this.$nextTick(() => {
        const choices = JSON.parse(JSON.stringify(this.filter.options))

        this.choicesLocal = choices.filter(choice => {
          const searchVal = this.searchValue.toLowerCase()
          const choiceName = (choice.label).toString().toLowerCase()

          if (choiceName.includes(searchVal)) {
            return choice
          }

          return false
        })
      })
    },
  },
}
</script>
