import { userDataResource } from '@services/cabinet.service.js'

export const user = {
  namespaced: true,

  state: {
    user: {},
    city: {
      id: window.initialCityPk,
      name: window.initialCityName,
    },
  },

  actions: {
    getUserData({ commit }) {
      return userDataResource.execute().then(res => {
        const { data: { item } } = res

        commit('CHANGE_USER_STATE', item)
      })
    },
  },

  mutations: {
    CHANGE_USER_STATE(state, payload) {
      state.user = {
        determinated: true,
        ...payload,
      }
    },

    SET_USER_CITY(state, payload) {
      state.city = payload
    },
  },
}
