<template lang="pug">
  include ../mixins
  +b.product-gallery
    +e.thumbs-wrapper(
      v-if="preparedImages.length > 1"
      ref="thumbsWrapper"
    )
      +e.scroller
        +b.product-thumb-scroller.is-spaced
          +e.item(
            v-for="(item, index) in preparedImages"
            ref="thumbs"
            :class="{ 'is-active': index === activeSlideIndex }"
            @mouseover="selectSlide(index)"
          )
            +e.PICTURE.image-wrapper
              source(
                v-if="item.image_url_sized.webp"
                type="image/webp"
                :srcset="item.image_url_sized.webp"
              )
              +e.IMG.image(:src="item.image_url_sized.base")
              +e.video-icon(v-if="item.url_video")
                icon-play-circle
        +e.more
          +b.A.ds-link.--color_main.--size_xxs(
            :href="showMoreUrl"
          ) {{ _("Фото і відео") }}
    +e.main-wrapper(
      v-if="preparedImages.length"
      :class="{ 'is-single': preparedImages.length === 1 }"
    )
      +e.main
        +e.arrow.--variant_prev.js-gallery-prev
          icon-chevron-left
        +e.arrow.--variant_next.js-gallery-next
          icon-chevron-right
        swiper(
          ref="slider"
          :options="swiperOptions"
          @slideChange="slideChangeHandler"
        )
          swiper-slide(
            v-for="(item, index) in preparedImages"
            :key="index"
          )
            modal-handler(
              url="Modals/Lightbox"
              modal-class="vm-modal--size_full"
              :object-item="{ images: preparedImages, initialSlide: activeSlideIndex }"
              #default="{ open }"
            )
              +e.gallery-item(@click.prevent="open")
                +e.PICTURE.image-wrapper
                  source(
                    v-if="item.image_url_sized.webp"
                    type="image/webp"
                    :srcset="item.image_url_sized.webp"
                  )
                  +e.IMG.main-image(:src="item.image_url_sized.base")
                +b.zoom-icon.--variant_play(v-if="item.url_video")
                  icon-play
                +b.zoom-icon(v-else)
                  icon-zoom-in
        +e.pagination.js-gallery-pagination(
          v-if="preparedImages.length > 1"
        )
    +e.PICTURE.placeholder-wrapper(v-if="!preparedImages.length")
      +e.IMG.placeholder(:src="productPlaceholder")
</template>

<script>
import { getVideoId, getVideoPreview } from '@utils/video'

export default {
  props: {
    images: {
      type: Array,
    },
    videos: {
      type: Array,
    },
    showMoreUrl: {
      type: String,
    },
  },

  data() {
    return {
      productPlaceholder: window.productPlaceholder,
      swiperOptions: {
        spaceBetween: 0,
        navigation: {
          prevEl: '.js-gallery-prev',
          nextEl: '.js-gallery-next',
        },
        pagination: {
          el: '.js-gallery-pagination',
          clickable: true,
        },
        effect: 'fade',
        slidesPerView: 1,
      },
      activeSlideIndex: 0,
    }
  },

  computed: {
    preparedImages() {
      const allItems = [...this.images, ...this.videos]

      return allItems.map(item => {
        if (item.url_video) {
          const videoId = getVideoId(item.url_video)
          const videoUrl = this.generateVideoUrl(videoId)

          if (!item.image_url.base) {
            const preview = getVideoPreview(videoId)

            item.image_url.base = preview
            item.image_url_sized.base = preview
          }

          item.url_video = videoUrl
        }

        return item
      })
    },
  },

  methods: {
    generateVideoUrl(videoId) {
      return `//www.youtube.com/embed/${videoId}?autoplay=1`
    },

    slideChangeHandler() {
      const { slider, thumbsWrapper, thumbs } = this.$refs

      this.activeSlideIndex = slider.swiper.activeIndex

      const currentThumb = thumbs[this.activeSlideIndex]
      const thumbHeight = currentThumb.clientHeight
      const offsetTop = currentThumb.offsetTop + thumbHeight

      if (offsetTop + thumbHeight >= thumbsWrapper.offsetHeight) {
        thumbsWrapper.scrollTop = offsetTop / 2
      }

      if (thumbsWrapper.scrollTop >= offsetTop - thumbHeight) {
        thumbsWrapper.scrollTop = 0
      }
    },

    selectSlide(index) {
      const { slider } = this.$refs

      slider.swiper.slideTo(index)
    },
  },
}
</script>
