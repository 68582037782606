<template lang="pug">
  include ../mixins
  +b.product-photos
    +e.previews
      +b.product-thumb-scroller
        +e.item(
          v-for="(item, index) in preparedImages"
          ref="thumb"
          :class="{ 'is-active': index === activeSlideIndex }"
          @click.prevent="scrollToSlide(index)"
        )
          +e.PICTURE.image-wrapper
            source(
              v-if="item.image_url_sized.webp"
              type="image/webp"
              :srcset="item.image_url_sized.webp"
            )
            +e.IMG.image(:src="item.image_url_sized.base")
            +e.video-icon(v-if="item.url_video")
              icon-play-circle
    +e.wrapper
      +e.content
        modal-handler(
          v-for="(item, index) in preparedImages"
          :key="index"
          url="Modals/Lightbox"
          modal-class="vm-modal--size_full"
          :object-item="{ images: preparedImages, initialSlide: index }"
          #default="{ open }"
        )
          +e.item(
            @click.prevent="open"
            ref="slide"
            :class="{ 'is-video': Boolean(item.url_video) }"
            :data-index="index"
            v-observe-visibility="{ callback: setVisibility, throttle: 300, intersection: { threshold: 0.7 } }"
          )
            +b.zoom-icon.--variant_play.--variant_bordered.--variant_visible(v-if="item.url_video")
              icon-play
            +b.zoom-icon(v-else)
              icon-zoom-in
            +e.image-wrapper
              picture
                source(
                  v-if="item.image_url_sized.webp"
                  type="image/webp"
                  :srcset="item.image_url_sized.webp"
                )
                +e.IMG.image(:src="item.image_url_sized.base")
</template>

<script>
import { getVideoId, getVideoPreview } from '@utils/video'

export default {
  props: {
    images: {
      type: Array,
    },
    videos: {
      type: Array,
    },
  },

  data() {
    return {
      activeSlideIndex: 0,
    }
  },

  computed: {
    preparedImages() {
      const allItems = [...this.images, ...this.videos]

      return allItems.map(item => {
        if (item.url_video) {
          const videoId = getVideoId(item.url_video)
          const videoUrl = this.generateVideoUrl(videoId)

          if (!item.image_url.base) {
            const preview = getVideoPreview(videoId)

            item.image_url.base = preview
            item.image_url_sized.base = preview
          }

          item.url_video = videoUrl
        }

        return item
      })
    },
  },

  methods: {
    generateVideoUrl(videoId) {
      return `//www.youtube.com/embed/${videoId}?autoplay=1`
    },

    scrollToSlide(index) {
      const headerHeight = document.querySelector('.header').clientHeight
      const navigationHeight = document.querySelector('.product-navigation').clientHeight
      const element = this.$refs.slide[index]
      const y = element.getBoundingClientRect().top + window.pageYOffset - headerHeight - navigationHeight

      window.scrollTo({ top: y, behavior: 'smooth' })
    },

    setVisibility(isReached, data) {
      if (isReached) {
        this.activeSlideIndex = Number(data.target.getAttribute('data-index'))
      }
    },
  },
}
</script>
