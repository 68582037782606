import {
  createResource,
  getResource,
  postResource,
} from '@resource/resource'
import { simpleTransformer } from '@resource/transformer'
import { CONTENT } from './paths'

export const contentCategoriesResource = createResource(
  CONTENT.CATEGORIES, getResource
)

export const contentPayloadsResource = createResource(
  CONTENT.PAYLOADS, getResource
)

export const contentExportCatalogResource = createResource(
  CONTENT.EXPORT_CATALOG, postResource, simpleTransformer
)

export const contentExportProductsResource = createResource(
  CONTENT.EXPORT_PRODUCTS, postResource, simpleTransformer
)
