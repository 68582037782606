<template lang="pug">
  include ../mixins
  +b.ui-search
    +e.toggler(
      @click.prevent="show"
      :class="{ 'is-active': isVisibleOnMobile }"
    )
      icon-search
    +e.main(:class="{ 'is-active': isVisibleOnMobile }")
      +e.blur(
        v-if="isFocused && hasResponse"
        @click="hide"
      )
      +e.wrapper
        +e.field
          +e.icon.--variant_search
            icon-search
          +e.input(:class="{ 'is-active': isFocused && hasResponse }")
            d-control-input(
              v-model="formdata.search",
              ref="search"
              input-class="d-control-input--variant_search"
              name="search"
              type="text"
              autocomplete="off"
              :placeholder="_('Я шукаю...')"
              @input="trimSearch();searchDebounce()"
              @focus="focusHandler"
              @keyup.enter="openSearchCatalog"
            )
          +e.icon.--variant_close(
            v-if="isVisibleOnMobile"
            @click.prevent="hide"
          )
            icon-close-medium
          +e.icon.--variant_close(
            v-if="isFocused && !isVisibleOnMobile && hasResponse"
            @click.prevent="hide"
          )
            icon-close-medium
          +e.button(v-if="!isFocused || (isFocused && !hasResponse)")
            +b.control-button.--variant_search(
              @click.prevent="setFocus"
            )
              +e.element
                +e.SPAN.content {{ _("Пошук") }}
        +e.content(v-if="isFocused && hasResponse")
          +e.preloader(v-if="isLoading")
            ui-preloader.i-preloader--relative
          +b.search-results(v-if="!isLoading")
            +e.main
              +e.part.--variant_empty(v-if="!result.count && !isLoading && hasResponse")
                +b.P.ds-caption.--size_sm.--color_relief-5 {{ _("Пошук не дав результатів") }}
              template(v-if="result.count")
                +e.links
                  +e.part(v-if="result.categories.length")
                    +e.title
                      +b.P.ds-caption.--size_xs.--color_relief-4 {{ _("Пошук по категоріям:") }}
                    +b.g-row.--appearance_spaced
                      +b.g-cell.g-cols.--12-xs(v-for="item in result.categories")
                        +b.g-row
                          +b.g-cell
                            +b.A.i-link.--color_dark(:href="item.link")
                              +e.icon.--offset_right.--color_relief-5
                                icon-catalog
                              +e.text
                                +b.SPAN.ds-caption.--size_2sm.--color_dark.--weight_bold {{ item.title }}
                  +e.part.--variant_products(v-if="result.items.length")
                    +e.title
                      +b.P.ds-caption.--size_xs.--color_relief-4 {{ _("Пошук по товарам:") }}
                    +b.g-row.--appearance_spaced
                      +b.g-cell.g-cols.--12-xs(v-for="item in result.items")
                        +b.A.search-product-link(
                          :href="item.link"
                          @mouseover="productHoverHandler(item)"
                        )
                          +e.PICTURE.image-wrapper
                            +e.IMG.image(:src="item.src")
                          +e.SPAN.text {{ item.titleProd }}
                +e.part.--variant_total.--space_1
                  +b.P.ds-caption.--size_sm.--color_relief-5 {{ _("Знайдено результатів пошуку:") }} {{ result.count }}
                +e.part.--variant_link
                  +b.A.ds-link.--size_sm.--color_main(:href="searchCatalogUrl") {{ _("Подивитися усі результати пошуку:") }} {{ result.count }}
            +e.products(v-if="productPreview")
              +e.part
                product-card-search(:item="productPreview")
              +e.part.--space_1
                +b.g-row.--justify_end
                  +b.g-cell
                    +b.A.ds-link.--color_main.--size_sm(
                      :href="searchCatalogUrl"
                    ) {{ _("Подивитися усі") }}
</template>

<script>
import { debounce } from 'lodash'
import { headerSearchResource } from '@services/common.service.js'

const MIN_LENGTH = 2

export default {
  name: 'ui-search',

  props: {
    link: String,
  },

  data() {
    return {
      formdata: {
        search: null,
      },
      result: {
        items: [],
        categories: [],
        isLimit: false,
        count: 0,
      },
      isFocused: false,
      isLoading: false,
      hasResponse: false,
      isVisibleOnMobile: false,
      searchDebounce: () => {},
      productPreview: null,
    }
  },

  computed: {
    searchCatalogUrl() {
      return `${this.link}search=${this.formdata.search}/`
    },

    searchIsAllowed() {
      return this.formdata.search.length >= MIN_LENGTH
    },
  },

  mounted() {
    this.searchDebounce = debounce(this.startSearch, 700)
  },

  methods: {
    show() {
      this.isVisibleOnMobile = true

      this.setFocus()
    },

    focusHandler() {
      this.isFocused = true
    },

    async setFocus() {
      this.focusHandler()

      await this.$nextTick()

      const field = this.$refs.search.$el.querySelector('input')

      field.focus()
    },

    hide() {
      this.isFocused = false
      this.isVisibleOnMobile = false

      this.clear()
    },

    clear() {
      this.formdata.search = ''

      this.hasResponse = false

      this.productPreview = null

      this.result = {
        items: [],
        isLimit: false,
        count: 0,
      }
    },

    async startSearch() {
      await this.$nextTick()

      if (this.searchIsAllowed) {
        this.getData()
      }
    },

    async trimSearch() {
      await this.$nextTick()

      this.formdata.search = this.formdata.search.trimStart().replace(/\s\s+/g, ' ')
    },

    getData() {
      this.isLoading = true

      const formdata = { search: this.formdata.search }

      headerSearchResource.execute({}, formdata).then(res => {
        const { data } = res

        this.result = data

        if (this.result.items.length) {
          const [firstItem] = this.result.items

          this.productPreview = firstItem
        }

        this.hasResponse = true
      }).finally(() => {
        this.isLoading = false
        this.hasResponse = true
      })
    },

    openSearchCatalog() {
      if (this.searchIsAllowed) {
        window.location = this.searchCatalogUrl
      }
    },

    productHoverHandler(item) {
      this.productPreview = item
    },
  },
}
</script>
