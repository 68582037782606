<script>
export default {
  props: {
    content: {
      type: String,
    },
  },

  methods: {
    doCopy() {
      this.$copyText(this.content).then(() => {
        this.$notify({
          title: this._('Скопійовано'),
          type: 'success',
        })
      }, () => {
        this.$notify({
          title: this._('Не вдалося скопіювати'),
          type: 'warn',
        })
      })
    },
  },

  render() {
    return this.$scopedSlots.default({
      doCopy: this.doCopy,
    })
  },
}
</script>
