<template lang="pug">
  include ../mixins
  +b.g-row.--appearance_spaced
    +b.g-cell.g-cols.--narrow-xs
      share-network.share-item(
        network="facebook"
        :url="url"
        title="Facebook"
      )
        icon-facebook
    +b.g-cell.g-cols.--narrow-xs
      share-network.share-item(
        network="twitter"
        :url="url"
        :title="title"
      )
        icon-twitter
    +b.g-cell.g-cols.--narrow-xs
      share-network.share-item(
        network="viber"
        :url="url"
        :title="title"
      )
        icon-viber
    +b.g-cell.g-cols.--narrow-xs
      share-network.share-item(
        network="telegram"
        :url="url"
        :title="title"
      )
        icon-telegram
    +b.g-cell.g-cols.--narrow-xs
      share-network.share-item(
        network="whatsapp"
        :url="url"
        :title="title"
      )
        icon-whatsapp
</template>

<script>
export default {
  props: {
    url: String,
    title: String,
  },
}
</script>
