<script>
import { mapState } from 'vuex'
import ChangeCity from '@app/Modals/City'

export default {
  computed: {
    ...mapState('user', ['city']),
  },

  methods: {
    cityChangedHandler() {
      this.$emit('city:changed')
    },

    open() {
      this.$modal.show(ChangeCity, {
        info: {
          callback: this.cityChangedHandler,
        },
      }, {
        classes: 'vm-modal--size_md vm-modal--overflow_initial',
      })
    },
  },

  render() {
    return this.$scopedSlots.default({
      open: this.open,
      userCity: this.city,
    })
  },
}
</script>
