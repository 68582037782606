<template lang="pug">
include ../mixins
+b.modal
  +b.close(@click="$emit('close')")
    icon-close
  +e.content
    +b.ds-panel.--space_xl.--space_3xl-sm.--space_5xl-xl
      +e.element.--offset_bottom
        +b.P.ds-caption.--size_lg.--weight_semi-bold {{ _("Виберіть своє місто") }}
    +b.relative
      ui-preloader(v-if="isLoad")
      +b.ds-panel.--space_xl.--space_3xl-sm.--space_5xl-xl
        +e.element.--offset_bottom
          +b.g-row.--appearance_spaced.--space_lg
            +b.g-cell.g-cols.--6-xs.--4-sm.--3-md(v-for="item in choices.quickCity")
              +b.g-row
                +b.g-cell
                  +b.city-tag(
                    @click.prevent="selectCity(item)"
                    :class="{ 'is-active': item.id === (formdata.city && formdata.city.id) }"
                  )
                    span {{ item.name }}
      validation-observer.relative(
        tag="form"
        ref="observer"
        v-slot="{ errors }"
        @submit.prevent="validateBeforeSubmit()"
      )
        +b.g-row.--appearance_spaced.--space_xl
          +b.g-cell.g-cols.--12-xs
            d-control-multiselect(
              :input-class="{ 'is-empty': !choices.city.length }"
              :options="choices.city"
              v-model="formdata.city"
              track-by="id"
              label="fullName"
              rules="required"
              name="city"
              :allow-empty="false"
              :placeholder="_('Введіть населений пункт України')"
              :searchable="true"
              :loading="cityLoading"
              :internal-search="false"
              :open-direction="isDesktop ? 'bottom' : 'top'"
              :max-height="isDesktop ? 260 : 140"
              @search-change="debounceSearch"
            )
              template(#noResult)
                span {{ _("За Вашим запитом нічого не знайдено") }}
              template(#noOptions)
                span {{ _("Почніть вводити назву") }}
          +b.g-cell.g-cols.--12-xs
            +b.BUTTON.control-button(
              @click.prevent="validateBeforeSubmit()"
              :disabled="isLoad"
            )
              +e.element
                +e.SPAN.text {{ _('Застосувати') }}
</template>

<script>
import { debounce } from 'lodash'
import { mapMutations } from 'vuex'
import FormMixin from '@mixins/form.mixin.js'
import {
  deliveryCityListMiniResource,
  deliveryCityChangeResource,
  deliveryCitySearchResource,
} from '@services/delivery.service.js'

export default {
  mixins: [FormMixin],

  props: {
    info: {},
  },

  data() {
    return {
      formdata: {
        city: null,
      },
      choices: {
        city: [],
        quickCity: [],
      },
      cityLoading: false,
      debounceSearch: () => {},
      isDesktop: 768 <= window.innerWidth,
    }
  },

  mounted() {
    this.debounceSearch = debounce(this.citiesSearch, 300)

    this.getCityQuickList()
  },

  methods: {
    ...mapMutations('user', [
      'SET_USER_CITY',
    ]),

    async getCityQuickList() {
      const { data: { items } } = await deliveryCityListMiniResource.execute()

      this.choices.quickCity = items
    },

    citiesSearch(search) {
      if (2 <= search.length) {
        this.cityLoading = true

        const params = { search }

        return deliveryCitySearchResource.execute(params).then(res => {
          this.choices.city = res.data.items

          this.cityLoading = false
        })
      }

      return true
    },

    selectCity(item) {
      this.formdata.city = item

      this.choices.city = [item]
    },

    send() {
      const formdata = { city: this.formdata.city.id }

      return deliveryCityChangeResource.execute({}, formdata).then(() => {
        this.$emit('close')

        this.SET_USER_CITY(this.formdata.city)

        this.info.callback()
      })
    },
  },
}
</script>
