<template lang="pug">
include ../mixins
+b.cart-product
  +e.actions-wrapper
    favorite-action(
      v-if="isAuthenticated"
      :is-active="product.isLiked"
      :item-id="product.id"
    )
      template(#default="{ action, isActive }")
        +e.action.--variant_heart(
          :class="{ 'is-active': isActive }"
          @click.prevent="action"
        )
          icon-heart-filled(v-if="isActive")
          icon-heart-empty(v-else)
    favorite-forbidden(v-else)
      template(#default="{ openForbiddenModal }")
        +e.action.--variant_heart(
          @click.prevent="openForbiddenModal"
        )
          icon-heart-empty
    +e.action.--variant_trash(@click.prevent="deleteItem")
      icon-trash
  +e.wrapper
    +e.top
      +e.A.image-wrapper(
        :href="product.link"
        target="_blank"
      )
        +b.ds-aspect-ratio.--appearance_filled.--ratio_4x4
          +e.body
            +b.PICTURE.image-wrapper
              +e.IMG.item.--fit(:src="product.img || productPlaceholder")
      +e.heading.--variant_mobile
        +b.A.ds-link.--size_xs.--color_dark.--weight_medium.--inline(
          :href="product.link"
          target="_blank"
        ) {{ product.title }}
        +e.vendor
          +b.P.ds-caption.--size_2xxs {{ _("Артикул:") }} {{ product.code }}
  +e.main
    +e.heading.--variant_desktop
      +b.A.ds-link.--size_2sm.--color_dark.--weight_medium.--inline(
        :href="product.link"
        target="_blank"
      ) {{ product.title }}
      +e.vendor
        +b.P.ds-caption.--size_2xs {{ _("Артикул:") }} {{ product.code }}
    +e.content
      +e.part.--variant_quantity.--width_1(
        :class="{ 'is-fullwidth': !product.isFreeDeliver && ((product.discountInfo && !product.discountInfo.discountSum) || !product.discountInfo) }"
      )
        +e.quantity
          ui-counter(
            v-if="!readonly"
            :max-count="product.availableAmount"
            :count="product.count"
            @input="updateCount($event)"
          )
      +e.part.--variant_delivery.--variant_1.--width_2(:class="{ 'is-active': product.isFreeDeliver }")
        +b.square-info
          +e.icon
            icon-delivery
          +e.content
            +b.P.ds-caption.--size_xxs.--size_xs-sm.--color_green {{ _("Безкоштовна доставка") }}
      +e.part.--variant_discount.--width_3(:class="{ 'without-delivery': !product.isFreeDeliver, 'is-active': product.discountInfo && product.discountInfo.discountSum }")
        +b.square-info
          +e.icon
            icon-percent
          +e.content
            +b.P.ds-caption.--size_xxs.--size_xs-sm.--color_relief-5 {{ _("Ви заощаджуєте:") }}
            +b.P.ds-caption.--size_xxs.--size_xs-sm.--color_red.--weight_bold {{ product.discountInfo.discountSum|prettifyPrice }} {{ _("грн") }} ({{product.discountInfo.discount}}%)
      +e.part.--variant_price.--width_4
        +e.price-wrapper
          template(v-if="product.oldTotalPrice")
            +b.P.ds-caption.--appearance_right-till-sm
              +b.SPAN.ds-caption.--size_2sm.--size_md-xl.--appearance_diagonal.--color_relief-5 {{ product.oldTotalPrice|prettifyPrice }}
              |&nbsp;
              +b.SPAN.ds-caption.--size_xxs.--size_2xs-xl.--color_relief-5 {{ _("грн") }}
            +b.P.ds-caption.--appearance_right-till-sm.--lh_3
              +b.SPAN.ds-caption.--size_lg.--size_2xl-xl.--color_red.--weight_semi-bold {{ product.totalPrice|prettifyPrice }}
              |&nbsp;
              +b.SPAN.ds-caption.--size_2xs.--size_md-xl.--color_red.--weight_semi-bold {{ _("грн") }}
          +b.P.ds-caption.--appearance_right-till-sm.--lh_3(v-else)
            +b.SPAN.ds-caption.--size_lg.--size_2xl-xl.--weight_semi-bold {{ product.totalPrice|prettifyPrice }}
            |&nbsp;
            +b.SPAN.ds-caption.--size_2xs.--size_md-xl.--weight_semi-bold {{ _("грн") }}
</template>

<script>
import { openConfirmModal } from '@utils/submit'

export default {
  components: {
    'ui-counter': () => import('@app/UiElements/UiCounter'),
  },

  props: {
    product: Object,
    readonly: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      isAuthenticated: window.isAuthenticated,
    }
  },

  methods: {
    updateCount(count) {
      const previousQuantity = Number(this.product.count)
      const quantity = count - previousQuantity

      this.$emit('update', { id: this.product.id, count: quantity })
    },

    async deleteItem() {
      const title = this._('Ви впевнені, що хочете видалити товар?')

      await openConfirmModal({ title }, this)

      const quantity = -Math.abs(this.product.count)

      this.$emit('delete', this.product)
      this.$emit('update', { id: this.product.id, count: quantity })
    },
  },
}
</script>
