export function cartData(ids, quantity) {
  const obj = {}
  const entities = []

  ids.forEach(id => {
    entities.push({
      element: {
        id,
        type: 'products.product',
      },
      quantity,
    })
  })

  obj.entities = entities

  return obj
}
