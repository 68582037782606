<script>
export default {
  name: 'ui-dropdown',

  props: {
    initialActive: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isActive: this.initialActive,
    }
  },

  methods: {
    hide() {
      this.isActive = false
    },

    show() {
      this.isActive = true
    },

    toggle() {
      this.isActive = !this.isActive
    },
  },

  render() {
    return this.$scopedSlots.default({
      hide: this.hide,
      show: this.show,
      toggle: this.toggle,
      isActive: this.isActive,
    })
  },
}
</script>
