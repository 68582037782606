import SuccessModal from '@app/Modals/SuccessModal'

const messageGetter = x => x.message

export function defaultValidatorErrorsParser(errors) {
  return Object.keys(errors).reduce((acc, x) => {
    if (Array.isArray(errors[x])) {
      acc[x] = errors[x].map(messageGetter)
    } else {
      acc[x] = [errors[x].message]
    }
    return acc
  }, {})
}

export async function useErrorsGetter(e) {
  const errors = {}

  await e.json().then(body => {
    body.errors.forEach(error => {
      if ('request' === error.domain && error.state) {
        Object.assign(errors, error.state)
      }
    })
  })

  const parsed = defaultValidatorErrorsParser(errors)

  return parsed
}

export default {
  data() {
    return {
      data: {},
      isLoad: false,
    }
  },

  methods: {
    catchFormErrors(promise) {
      return promise.catch(e => {
        const clientError = 400
        const serverError = 500

        if (!e.status || clientError > e.status || serverError <= e.status) {
          throw e
        }

        if (!this.updateValidator) {
          return e
        }

        return e.json().then(body => {
          const errors = {}

          body.errors.forEach(error => {
            if ('request' === error.domain && error.state) {
              Object.assign(errors, error.state)
            }
          })

          this.updateValidator(errors)

          return e
        })
      })
    },

    submit(isValid, handler) {
      if (!isValid) {
        return Promise.reject()
      }

      this.isLoad = true

      return this.catchFormErrors(handler(this.formdata))
        .finally(() => {
          this.isLoad = false
        })
    },

    async validateBeforeSubmit(handler = this.send) {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.submit(isValid, handler)
      } else {
        if (this.showWarningModal) {
          this.showWarningModal()
        }

        this.isLoad = false
      }
    },

    handleResponse(response, action) {
      Object.keys(this.formdata).forEach(key => {
        this.formdata[key] = ''
      })

      if (response.modal) {
        this.$emit('close')

        this.showModal(
          response.res.data.item.message.title,
          response.res.data.item.message.text,
          action
        )
      }

      if (response.redirect) {
        window.location.replace(response.res.data.item.location)
      }

      this.$refs.observer.reset()
    },

    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)

      this.$refs.observer.setErrors(parsed)
    },

    showModal(title, text, action) {
      this.$modal.show(SuccessModal, {
        info: {
          title,
          text,
        },
      }, {}, {
        'before-close': () => { this.$emit('action') },
        closed: () => { if (action) action() },
      })
    },

    notification(title) {
      this.$notify({
        title,
        type: 'warn',
      })
    },
  },
}
