<template lang="pug">
  include ../mixins
  +b.inliner--center.configurator-button.display_block.A(:href="link")
    +b.action-button.ma--right_2xs-xl.DIV
      +e.wrapper
        +e.button.u-display--hidden-xl.BUTTON
          +b.ICON-CONSTRUCTOR.i-icon.--color_accent-1.--size_sm
        +e.button--gray.--icon-accent-1.u-display--hidden.u-display--block-xl.BUTTON
          +b.ICON-CONSTRUCTOR.i-icon.--color_accent-1.--size_sm
        +e.counter(v-if="countItem > 0") {{ countItem }}
    bem-text.ma--left_xs.u-display--hidden.u-display--block-xl(
      tag="p"
      color="accent-1"
      variant="center"
      size="2xs"
    ) {{_('Конфігуратор')}}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'configurator-button',
  props: {
    count: String,
    link: String,
  },
  data() {
    return {
      countItem: this.count,
    }
  },
  watch: {
    'getActionsData.configurator': {
      handler(val) {
        this.countItem = val
      },
    },
  },
  computed: {
    ...mapGetters('common', [
      'getActionsData',
    ]),
  },
}
</script>
