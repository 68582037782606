import {
  createResource,
  getResource,
} from '@resource/resource'
import { PAGINATION } from './paths'

export const articlesPaginationResource = createResource(
  PAGINATION.ARTICLE_PAGINATION, getResource
)

export const videoPaginationResource = createResource(
  PAGINATION.VIDEO_PAGINATION, getResource
)

export const setPaginationResource = createResource(
  PAGINATION.SET_PAGINATION, getResource
)

export const stockPaginationResource = createResource(
  PAGINATION.STOCK_PAGINATION, getResource
)

export const stockPaginationLabelResource = createResource(
  PAGINATION.STOCK_PAGINATION_LABEL, getResource
)

export const stockProductsPaginationResource = createResource(
  PAGINATION.STOCK_PRODUCTS_PAGINATION, getResource
)
