import Vue from 'vue'

Vue.directive('product-characteristics-link', {
  bind: node => {
    setTimeout(() => {
      const target = node.querySelector('.js-characteristics-link')

      if (!target) return

      node.scrollTo({ left: target.offsetLeft })
    }, 500)
  },
})
