<template lang="pug">
  include ../mixins
  +b.cookie-panel(v-if="showNotification")
    +e.close(@click.prevent="close")
      icon-close
    +e.content
      +b.P.ds-caption.--size_xxs.--size_xs-sm.--size_2sm-lg.--color_white {{ _("Ми використовуємо файли cookie для покращення взаємодії та аналізу трафіку на нашому вебсайт. Використовуючи цей вебсайт, ви приймаєте нашу") }}
        | 
        +b.A.ds-link.--size_xxs.--size_xs-sm.--size_2sm-lg.--color_white.--underline.--inline(
          :href="policyPageUrl"
          target="_blank"
        ) {{ _("Політику конфіденційності") }}.
</template>

<script>
export default {
  data() {
    return {
      showNotification: false,
      policyPageUrl: window.policyPageUrl,
    }
  },

  mounted() {
    this.checkCookie('notification')
  },

  methods: {
    close() {
      const expireDate = 60 * 60 * 24 * 365

      document.cookie = `notification=true; max-age=${expireDate}; path=/`

      this.showNotification = false
    },

    checkCookie(name) {
      const matches = document.cookie.match(new RegExp(
        `(?:^|; )${ name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') }=([^;]*)`
      ))
      if (null === matches) {
        this.showNotification = true
      } else {
        this.showNotification = Boolean(!decodeURIComponent(matches[1]))
      }
    },
  },
}
</script>
