import Components from '@app'

import VueI18n from 'vue-i18n'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import { ObserveVisibility } from 'vue-observe-visibility'

import VModal from 'vue-js-modal'
import VTooltip from 'v-tooltip'
import VueSocialSharing from 'vue-social-sharing'
import GridPlugin from '@aspectus/vue-grid'
import Notifications from 'vue-notification'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import PortalVue from 'portal-vue'
import * as VueGoogleMaps from 'vue2-google-maps'
// import Vue2TouchEvents from 'vue2-touch-events'
import VueClipboard from 'vue-clipboard2'

const VueInputMask = require('vue-inputmask').default

export function install(Vue) {
  Vue.use(VueI18n)
  Vue.use(VueRouter)
  Vue.use(Vuex)
  Vue.use(GridPlugin)
  Vue.use(VueSocialSharing)
  Vue.use(VTooltip)
  Vue.use(VModal, {
    dynamic: true,
    dialog: true,
    dynamicDefaults: {
      height: 'auto',
      transition: 'false',
      scrollable: true,
    },
  })
  Vue.use(VueGoogleMaps, {
    load: {
      key: window.googleMapKey,
      libraries: 'places',
    },
  })
  Vue.use(Notifications)
  Vue.use(VueInputMask)
  Vue.use(Components)
  Vue.use(VueAwesomeSwiper)
  Vue.use(PortalVue)
  // Vue.use(Vue2TouchEvents)
  Vue.use(VueClipboard)

  Vue.directive('observe-visibility', ObserveVisibility)
}

export default { install }
