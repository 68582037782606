<script>
export default {
  name: 'modal-handler',

  props: {
    objectItem: {
      required: false,
    },
    url: {
      type: String,
    },
    modalClass: {
      type: String,
      default: 'vm-modal--size_sm',
    },
  },

  computed: {
    componentLoader() {
      return () => import(`@app/${this.url}`)
    },
  },

  methods: {
    open() {
      this.$modal.show(this.componentLoader, {
        objectItem: this.objectItem,
      }, {
        classes: this.modalClass,
      })
    },
  },

  render() {
    return this.$scopedSlots.default({
      open: this.open,
    })
  },
}
</script>
