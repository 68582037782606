<template lang="pug">
  include ../../mixins
  +b.ticket.--variant_1
    +e.heading.--variant_1
      +b.P.ds-caption.--size_2sm.--color_dark.--weight_medium {{ item.name }}
      +e.rating(v-if="item.appealType === 'review'")
        ui-star-rating.vue-star-rating--variant_1(
          :read-only="true"
          :current-rating="item.point"
          :star-size="17"
        )
    +e.main
      +e.wrapper
        +b.ds-panel.--space_md.--space_xl-xl
          +e.element.--offset_bottom
            +b.g-row.--justify_between.--align_center
              +b.g-cell
                +b.stock-label(v-if="item.appealType === 'review'")
                  +e.icon
                    icon-checkmark
                  +e.text
                    +b.SPAN.ds-caption.--size_xs.--size_2sm-xl {{ _("Куплено") }}
                +b.stock-label(v-else)
                  +e.icon
                    icon-info
                  +e.text
                    +b.SPAN.ds-caption.--size_xs.--size_2sm-xl {{ _("Питання") }}
              +b.g-cell
                +b.P.ds-caption.--size_xs.--size_2xs-xl.--lh_13.--color_relief-4 {{ item.date }}
        +e.item
          +e.text
            +b.P.ds-caption.--size_xs.--size_2xs-xl.--lh_13.--color_grey.pre-line {{ item.content }}
          +e.text(v-if="item.advantages")
            +b.ds-panel.--space_sm.--space_md-xl
              +e.element.--offset_bottom
                +b.P.ds-caption.--size_xs.--size_2sm-xl.--weight_medium {{ _("Переваги:") }}
            +b.P.ds-caption.--size_xs.--size_2xs-xl.--lh_13.--color_grey.pre-line {{ item.advantages }}
          +e.text(v-if="item.flaws")
            +b.ds-panel.--space_sm.--space_md-xl
              +e.element.--offset_bottom
                +b.P.ds-caption.--size_xs.--size_2sm-xl.--weight_medium {{ _("Недоліки:") }}
            +b.P.ds-caption.--size_xs.--size_2xs-xl.--lh_13.--color_grey.pre-line {{ item.flaws }}
          +e.images(v-if="preparedImages.length")
            +b.upload-images
              +e.item(
                v-for="(image, index) in preparedImages"
                :class="{ 'is-video': Boolean(item.url_video) }"
              )
                modal-handler(
                  url="Modals/Lightbox"
                  modal-class="vm-modal--size_full"
                  :object-item="{ images: preparedImages, initialSlide: activeSlideIndex }"
                  #default="{ open }"
                )
                  +e.preview(@click.prevent="selectPhoto(index, open)")
                    +e.PICTURE.image-wrapper
                      source(
                        type="image/webp"
                        :srcset="image.image_url.webp"
                      )
                      +e.IMG.image(:src="image.image_url.base")
                    +e.icon(v-if="Boolean(item.url_video)")
                      icon-play-circle
                    +e.icon.--variant_zoom(v-else)
                      icon-zoom-in
        +e.footer
          +e.action
            +b.i-link.--size_xs.--size_2xs-sm.--color_main.--weight_medium(
              v-if="item.answersReview.length"
              @click.prevent="toggleAnswers"
            )
              +e.icon.--color_relief-4.--offset_right
                icon-message
              +e.SPAN.text {{ _("Відповіді") }} ({{ item.answersReview.length }})
              +e.icon.--color_relief-4.--offset_left(
                :class="{ 'is-rotate': answersIsOpen }"
              )
                icon-chevron-down
            +b.i-link.--size_xs.--size_2xs-sm.--color_3.--weight_medium.--hover_none(
              v-else
            )
              +e.icon.--color_relief-4.--offset_right
                icon-message
              +e.SPAN.text {{ _("Немає відповідей") }}
          +e.action
            +b.i-link.--size_xs.--size_2xs-sm.--color_main.--weight_medium(
              @click.prevent="toggleComment"
            )
              +e.SPAN.text(v-if="commentIsOpen") {{ _("Приховати") }}
              +e.SPAN.text(v-else) {{ _("Коментувати") }}
              +e.icon.--color_relief-4.--offset_left(
                :class="{ 'is-rotate': commentIsOpen }"
              )
                icon-chevron-down
      +e.form.--variant_1(v-show="commentIsOpen")
        product-review-comment-form(:review-id="item.id")
      +e.answers(v-show="answersIsOpen")
        +b.ticket-answer.--bg_white(
          v-for="answer in item.answersReview"
          :class="{ 'is-company': answer.isRepresentativeCompany }"
        )
          +e.heading
            //- +b.g-row.--align_center(v-if="answer.isRepresentativeCompany")
            //-   +b.g-cell
            //-     +b.ticket-company-logo
            //-       +e.IMG.image(:src="'/static/img/logo-small.png'")
            //-   +b.g-cell
            //-     +b.P.ds-caption.--size_2xs.--size_2sm-sm.--weight_medium {{ answer.name }}
            +b.P.ds-caption.--size_2xs.--size_2sm-sm.--weight_medium {{ answer.name }}
          +e.content
            +e.title
              +b.P.ds-caption.--size_xs.--size_2xs-xl.--lh_13.--color_grey.pre-line {{ answer.content }}
</template>

<script>
import Forbidden from '@app/Modals/Authentication/Forbidden'
import { getVideoId, getVideoPreview } from '@utils/video'

export default {
  props: {
    item: {},
  },

  data() {
    return {
      answersIsOpen: true,
      commentIsOpen: false,
      isAuth: window.isAuthenticated,
      activeSlideIndex: 0,
    }
  },

  computed: {
    preparedImages() {
      const photos = [...this.item.photos]

      if (this.item.videoUrl) {
        const videoId = getVideoId(this.item.videoUrl)
        const videoUrl = this.generateVideoUrl(videoId)
        const preview = getVideoPreview(videoId)

        photos.push({
          videoUrl,
          imageUrl: {
            base: preview,
          },
          imageUrlSized: {
            base: preview,
          },
        })
      }

      return photos.map(el => {
        const obj = {
          image_url: el.imageUrl,
          image_url_sized: el.imageUrlSized,
          url_video: el.videoUrl,
        }

        return obj
      })
    },
  },

  methods: {
    generateVideoUrl(videoId) {
      return `//www.youtube.com/embed/${videoId}?autoplay=1`
    },

    openForbiddenModal() {
      this.$modal.show(Forbidden, {
        info: {
          title: this._('Коментувати можуть лише авторизовані користувачі'),
        },
      }, {
        classes: 'vm-modal--size_2sm',
      })
    },

    toggleAnswers() {
      this.answersIsOpen = !this.answersIsOpen
    },

    toggleComment() {
      if (this.isAuth) {
        this.commentIsOpen = !this.commentIsOpen
      } else {
        this.openForbiddenModal()
      }
    },

    selectPhoto(index, open) {
      this.activeSlideIndex = index

      this.$nextTick(() => {
        open()
      })
    },
  },
}
</script>
