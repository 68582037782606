<template lang="pug">
  include ../mixins
  validation-observer.relative(
    tag="form"
    ref="observer"
    v-slot="{ errors }"
    @submit.prevent="validateBeforeSubmit()"
  )
    ui-preloader(v-if="isLoad")
    +b.g-row.--appearance_spaced.--space_xl.--space_3xl-xl
      +b.g-cell.g-cols.--12-xs(:class="{ 'g-cols--6-sm': optimized }")
        d-control-input(
          rules="email|required"
          type="text"
          :input-label="_('Email')"
          v-model="formdata.emailPhone"
          name="emailPhone"
        )
      +b.g-cell.g-cols.--12-xs(:class="{ 'g-cols--6-sm': optimized }")
        d-control-input(
          rules="required|min:8"
          ref='password'
          type="password"
          v-model="formdata.password"
          :input-label="_('Пароль')"
          name="password"
        )
      +b.g-cell.g-cols.--12-xs
        +b.BUTTON.control-button(
          @click.prevent="validateBeforeSubmit()"
          :disabled="isLoad"
        )
          +e.element
            +e.SPAN.text {{ _('Увійти') }}
      validation-provider(
        slim
        v-slot="{ errors }"
        name="nonFieldErrors"
      )
        +b.g-cell.g-cols.--12-xs(v-if="errors[0]")
          +b.P.error-label {{ errors[0] }}
      +b.g-cell.g-cols.--12-xs
        +b.g-row.--justify_center
          +b.g-cell
            +b.A.ds-link.--size_sm.--color_main.--inline(
              :href="forgotPasswordPageUrl"
            ) {{ _("Забули пароль?") }}
      +b.g-cell.g-cols.--12-xs
        +b.g-row.--justify_center
          +b.g-cell
            +b.ds-link.--size_sm.--color_main.--inline(
              @click.prevent="$emit('method:change', 'phone')"
            ) {{ _("Увійти за номером телефону") }}
</template>

<script>
import { signInResource } from '@services/auth.service.js'
import FormMixin from '@mixins/form.mixin.js'

export default {
  mixins: [FormMixin],

  props: {
    needReload: {
      type: Boolean,
    },
    optimized: {
      type: Boolean,
    },
  },

  data() {
    return {
      formdata: {
        emailPhone: '',
        password: '',
      },
      forgotPasswordPageUrl: window.forgotPasswordPageUrl,
    }
  },

  methods: {
    send(data) {
      return signInResource.execute({}, data).then(() => {
        if (this.needReload) {
          window.location.reload()
        } else {
          const redirect = this.getRedirectUrl()

          if (redirect) {
            window.location = redirect
          } else {
            window.location = window.indexPageUrl
          }
        }
      })
    },

    getRedirectUrl() {
      const params = new URLSearchParams(window.location.search)
      const redirect = params.get('next')

      return redirect
    },
  },
}
</script>
