<template lang="pug">
include ../mixins
swiper(:options="swiperOptions")
  slot
</template>

<script>
export default {
  data() {
    return {
      swiperOptions: {
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 3000,
        effect: 'fade',
      },
    }
  },
}
</script>
