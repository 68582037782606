import { getCookie } from '@utils/cookie'

const TIME_DELTA = 60
const milliseconds = 1000
const timeDeltaInMilliseconds = TIME_DELTA * milliseconds

export default {
  data() {
    return {
      time: '00',
      resendBlocked: true,
      timeInterval: null,
    }
  },

  beforeDestroy() {
    clearInterval(this.timeInterval)
  },

  methods: {
    getTimeDiff() {
      const lastSendAt = getCookie('code_send_at')

      const currentDate = new Date().getTime()
      const reminder = Math.floor((currentDate - lastSendAt) / milliseconds)
      const diff = TIME_DELTA - reminder

      const seconds = 0 < reminder ? diff : TIME_DELTA

      return {
        seconds,
        diff,
      }
    },

    startTimer() {
      clearInterval(this.timeInterval)

      const lastSendAt = getCookie('code_send_at')

      let { seconds } = this.getTimeDiff()

      this.resendBlocked = true

      this.timeInterval = setInterval(() => {
        seconds -= 1

        if (seconds < 0) {
          seconds = 0
        }

        const prettySeconds = seconds >= 10 ? seconds : `0${seconds}`

        this.time = prettySeconds

        const now = new Date().getTime()

        if ((now - lastSendAt) > timeDeltaInMilliseconds) {
          clearInterval(this.timeInterval)

          this.resendBlocked = false
        }
      }, 1000)
    },
  },
}
