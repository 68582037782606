<template lang="pug">
include ../mixins
validation-observer(
  tag="form"
  ref="observer"
  v-slot="{ errors }"
  @submit.prevent="validateBeforeSubmit()"
)
  +b.g-row.--appearance_spaced.--space_xl
    +b.g-cell.g-cols.--12-xs
      d-control-input(
        rules="required|username"
        type="text"
        :input-label="labels.name"
        v-model="formdata.name"
        name="name"
      )
    +b.g-cell.g-cols.--12-xs.--6-sm
      d-control-input(
        :rules="{ required: !formdata.email || Boolean(formdata.phone) }"
        type="tel"
        inputmode="tel"
        :input-label="labels.phone"
        v-model="formdata.phone"
        mask="+38(###)###-##-##"
        name="phone"
      )
    +b.g-cell.g-cols.--12-xs.--6-sm
      d-control-input(
        :rules="{ required: !formdata.phone, email: true }"
        type="email"
        inputmode="email"
        :input-label="labels.email"
        v-model="formdata.email"
        name="email"
      )
    +b.g-cell.g-cols.--12-xs
      d-control-textarea(
        rules="required"
        :input-label="labels.message"
        input-class="d-control-input--height_3"
        v-model="formdata.message"
        name="message"
      )
    +b.g-cell.g-cols.--12-xs
      +b.g-row.--appearance_spaced.--space_2xl.--justify_between.--align_center
        +b.g-cell.g-cols.--12-xs.--narrow-sm
          slot(name="socials")
        +b.g-cell.g-cols.--12-xs.--narrow-sm
          +b.BUTTON.control-button(
            @click.prevent="validateBeforeSubmit()"
            :disabled="isLoad"
          )
            +e.element
              +e.SPAN.text {{ _('Надіслати') }}
    validation-provider(
      slim
      v-slot="{ errors }"
      name="nonFieldErrors"
    )
      +b.g-cell.g-cols.--12-xs(v-if="errors[0]")
        +b.P.error-label {{ errors[0] }}
</template>

<script>
import { mapState } from 'vuex'
import FormMixin from '@mixins/form.mixin.js'
import { feedbackRecource } from '@services/common.service.js'

export default {
  mixins: [FormMixin],

  data() {
    return {
      formdata: {
        name: '',
        email: '',
        phone: '',
        message: '',
      },
      isAuth: window.isAuthenticated,
      getParams: {},
      labels: {
        name: this._('Ваше ім`я'),
        phone: this._('Ваш телефон'),
        email: this._('Ваш Email'),
        message: this._('Ваше питання'),
      },
    }
  },

  watch: {
    user: {
      deep: true,
      immediate: true,
      handler(nval) {
        if (this.isAuth) {
          this.formdata.name = `${nval.firstName} ${nval.lastName}`
          this.formdata.phone = nval.phone ? `+${nval.phone}` : ''
          this.formdata.email = nval.email
        }
      },
    },
  },

  computed: {
    ...mapState('user', ['user']),
  },

  mounted() {
    this.parseParameters()
  },

  methods: {
    send(data) {
      data.getParams = this.getParams

      return feedbackRecource.execute({}, data)
        .then(res => {
          this.handleResponse({ modal: true, res })
        })
    },
    parseParameters() {
      const url = new URL(window.location.href)
      const searchParams = url.search.replace('?', '')
      const paramsElements = searchParams.split('&')

      paramsElements.forEach(el => {
        const data = el.split('=')
        const [key, value] = data
        this.getParams[key] = value
      })
    },
  },
}
</script>
