const bodyEl = document.querySelector('body')
const htmlEl = document.querySelector('html')

let scrollPosition = 0

export const scrollLock = () => {
  scrollPosition = window.pageYOffset

  bodyEl.classList.add('is-overflow')

  bodyEl.style.position = 'fixed'
  bodyEl.style.top = `-${scrollPosition}px`
  bodyEl.style.width = '100%'

  bodyEl.classList.add('disable-smooth')
  htmlEl.classList.add('disable-smooth')
}

export const scrollUnlock = () => {
  bodyEl.classList.remove('is-overflow')

  bodyEl.style.removeProperty('position')
  bodyEl.style.removeProperty('top')
  bodyEl.style.removeProperty('width')

  window.scrollTo(0, scrollPosition)

  bodyEl.classList.remove('disable-smooth')
  htmlEl.classList.remove('disable-smooth')
}
