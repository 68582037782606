import {
  postResource,
  createResource,
  getResource,
} from '@resource/resource'
import { REVIEW } from './paths'

export const addReviewResource = createResource(
  REVIEW.CREATE, postResource
)

export const addQuestionResource = createResource(
  REVIEW.QUESTION_CREATE, postResource
)

export const reviewListAllResource = createResource(
  REVIEW.LIST_ALL, getResource
)

export const reviewListResource = createResource(
  REVIEW.LIST, getResource
)

export const reviewListQuestionResource = createResource(
  REVIEW.LIST_QUESTION, getResource
)

export const reviewAnswerAddResource = createResource(
  REVIEW.ANSWER_ADD, postResource
)
