<script>
import { logoutResource } from '@services/auth.service.js'
import { openConfirmModal } from '@utils/submit'

export default {
  methods: {
    async logout() {
      const title = this._('Ви впевнені, що хочете вийти?')

      await openConfirmModal({ title }, this)

      logoutResource.execute({}).then(() => {
        window.location = window.indexPageUrl
      })
    },
  },

  render() {
    return this.$scopedSlots.default({
      logout: this.logout,
    })
  },
}
</script>
