<template lang="pug">
include ../mixins
swiper(
  v-if="showSwiper"
  ref="slider"
  :options="swiperOptions"
)
  slot
</template>

<script>
import { debounce } from 'lodash'

export default {
  data() {
    return {
      swiperOptions: {
        autoHeight: true,
        spaceBetween: 10,
        navigation: {
          prevEl: '.js-image-grid-prev',
          nextEl: '.js-image-grid-next',
        },
        pagination: {
          el: '.js-image-grid-pagination',
          clickable: true,
        },
        slidesPerView: 1,
      },
      showSwiper: true,
      resizeHandlerDebounce: () => {},
    }
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.resizeHandlerDebounce)
  },

  mounted() {
    this.resizeHandlerDebounce = debounce(this.resizeHandler, 100)

    this.resizeHandlerDebounce()

    window.addEventListener('resize', this.resizeHandlerDebounce)
  },

  methods: {
    resizeHandler() {
      const { swiper } = this.$refs.slider

      if (564 <= window.innerWidth) {
        swiper.destroy()
      } else if (!swiper.initialized) {
        this.showSwiper = false

        this.$nextTick(() => {
          this.showSwiper = true
        })
      }
    },
  },
}
</script>
