<script>
import { mapState } from 'vuex'

export default {
  props: {
    count: Number,
  },

  data() {
    return {
      totalCount: Number(this.count),
    }
  },

  computed: {
    ...mapState('cart', ['cartCount']),
  },

  watch: {
    cartCount: {
      deep: true,
      handler(nval) {
        this.totalCount = nval.quantity
      },
    },
  },

  methods: {
    openModal() {
      const modal = () => import('@app/Cart/CartView')

      this.$modal.show(modal, {}, {
        classes: 'vm-modal--size_2xl vm-modal--radius_none-till-xl',
      })
    },
  },

  render() {
    return this.$scopedSlots.default({
      totalCount: this.totalCount,
      openModal: this.openModal,
    })
  },
}
</script>
