<script>
import { mapActions } from 'vuex'
import { turnConfiguratorResource } from '@services/configurator.service.js'

export default {
  name: 'configurator-action',
  props: {
    productId: {},
    isActive: {},
  },
  data() {
    return {
      isActiveProduct: false,
    }
  },
  mounted() {
    if ('boolean' === typeof this.isActive) this.isActiveProduct = this.isActive
    else this.isActiveProduct = this.isActive
  },
  methods: {
    ...mapActions('common', [
      'changeAction',
    ]),

    send() {
      return turnConfiguratorResource.execute({}, { id_prod: this.productId })
        .then(res => {
          this.isActiveProduct = res.data.item.create
          this.changeAction({ key: 'configurator', res: res.data.item.countProduct })
          this.showNotification()
        })
    },

    showNotification() {
      if (this.isActiveProduct) {
        this.$notify({
          title: this._('Додано до конфігуратора'),
          type: 'success',
        })
      } else {
        this.$notify({
          title: this._('Видалено з конфігуратора'),
          type: 'success',
        })
      }
    },
  },
  render() {
    return this.$scopedSlots.default({
      action: this.send,
      isActive: this.isActiveProduct,
    })
  },
}
</script>
