import { disableAnimation } from './disable-animation'
import { sliderSnap } from './slider-snap'
import { videoSlider } from './video-slider'
import { categoryModal } from './category-modal'

document.addEventListener('DOMContentLoaded', () => {
  disableAnimation()
  sliderSnap()
  videoSlider()
  categoryModal()
}, { passive: true })
