<template lang="pug">
  include ../mixins
  +b.collapse
    +e.content(
      ref="content"
      :style="{ height: `${contentCollapsedHeight}px` }"
      :class="{ 'is-active': isOpened }"
    )
      slot
    +e.button(
      v-if="isShowButton"
      :class="{ 'is-active': isOpened }"
      @click="toggleCollapse"
    )
      +b.i-link
        +e.content
          +b.SPAN.ds-caption.--size_2xs.--size_2sm-xl.--color_main {{ isOpened ? _("Дивитись меньше") : _("Дивитись більше") }}
        +e.icon.--offset_left.--color_relief-4(:class="{ 'is-rotate': isOpened }")
          icon-chevron-down
</template>

<script>
export default {
  props: {
    heights: {
      type: Object,
    },
    rows: {
      type: Number,
    },
  },

  data() {
    return {
      isOpened: false,
      isShowButton: false,
      contentCollapsedHeight: 0,
    }
  },

  mounted() {
    this.showButton()
  },

  methods: {
    toggleCollapse() {
      this.isOpened = !this.isOpened
    },

    getContentCollapsedHeight() {
      if (this.rows) {
        const items = [...this.$refs.content.querySelectorAll('tr')]
        const slicedItems = items.slice(0, this.rows)
        const totalHeightOfSlicedItems = slicedItems.reduce((acc, el) => {
          acc += el.clientHeight

          return acc
        }, 0)

        return totalHeightOfSlicedItems
      }

      const screenWidth = Object.keys(this.heights)
      const height = Object.values(this.heights)

      for (let i = 0; i < screenWidth.length; i++) {
        if (window.matchMedia(`(max-width: ${screenWidth[i]}px)`).matches) {
          return height[i]
        }
      }

      return 0
    },

    showButton() {
      this.contentCollapsedHeight = this.getContentCollapsedHeight()

      if (this.$refs.content.scrollHeight > this.contentCollapsedHeight) {
        this.isShowButton = true
      }
    },
  },
}
</script>
