<template lang="pug">
  include ../../mixins
  +b.modal
    +b.close(@click="$emit('close')")
      icon-close
    +e.heading.--variant_4
      +b.P.ds-caption.--size_lg.--weight_semi-bold.--appearance_center {{ info.title }}
    +e.wrapper.--variant_2
      +b.ds-panel.--space_6xl
        +e.element.--offset_bottom
          +b.P.ds-caption.--size_md.--color_relief-4.--appearance_center {{ _("будь ласка авторизуйтеся / зареєструйтеся") }}
      +b.g-row.--space_xl.--space_3xl-xl.--appearance_spaced
        +b.g-cell.g-cols.--12-xs.--6-sm
          +b.A.control-button.--variant_bordered(:href="loginPageUrl")
            +e.element
              +e.SPAN.text {{ _("Авторизація") }}
        +b.g-cell.g-cols.--12-xs.--6-sm
          +b.A.control-button(:href="registrationPageUrl")
            +e.element
              +e.SPAN.text {{ _("Реєстрація") }}
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
    },
  },

  data() {
    return {
      loginPageUrl: window.loginPageUrl,
      registrationPageUrl: window.registrationPageUrl,
    }
  },
}
</script>
