import Actions from './Actions'
import Catalog from './Catalog'
import Configurator from './Configurator'
import Controls from './Controls'
import Forms from './Forms'
import UiElements from './UiElements'
import Checkout from './Checkout'
import ExportContent from './ExportContent'
import Icons from './Icons'
import Compare from './Compare'
import Favorite from './Favorite'
import Cart from './Cart'
import Sliders from './Sliders'
import Authentication from './Authentication'
import Subscribe from './Subscribe'
import Product from './Product'

export default function plugin(Vue) {
  Vue.use(Actions)
  Vue.use(Catalog)
  Vue.use(Configurator)
  Vue.use(Controls)
  Vue.use(Forms)
  Vue.use(Checkout)
  Vue.use(UiElements)
  Vue.use(ExportContent)
  Vue.use(Icons)
  Vue.use(Compare)
  Vue.use(Favorite)
  Vue.use(Cart)
  Vue.use(Sliders)
  Vue.use(Authentication)
  Vue.use(Subscribe)
  Vue.use(Product)
}
