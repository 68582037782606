import Vue from 'vue'

import { scrollUnlock, scrollLock } from '@utils/scroll'

Vue.directive('sidebar', {
  bind: node => {
    const togglers = [...node.querySelectorAll('.js-sidebar-toggler')]
    const sidebar = node.querySelector('.js-sidebar')
    const blur = node.querySelector('.js-sidebar-blur')
    const closeItems = [...node.querySelectorAll('.js-sidebar-close')]

    const navigation = node.querySelector('.js-navigation')
    const navigationBlur = node.querySelector('.js-navigation-blur')

    togglers.forEach(item => {
      item.addEventListener('click', () => {
        sidebar.classList.toggle('is-active')
        blur.classList.toggle('is-active')

        navigation.classList.remove('is-active')
        navigationBlur.classList.remove('is-active')

        scrollLock()
      })
    })

    blur.addEventListener('click', () => {
      sidebar.classList.remove('is-active')
      blur.classList.remove('is-active')

      scrollUnlock()
    })

    closeItems.forEach(item => {
      item.addEventListener('click', () => {
        sidebar.classList.remove('is-active')
        blur.classList.remove('is-active')
  
        scrollUnlock()
      })
    })

    if (window.isAuthenticated) {
      const sidebarUserbarButton = node.querySelector('.js-sidebar-userbar-open')
      const sidebarUserbarClose = node.querySelector('.js-sidebar-userbar-close')

      sidebarUserbarButton.addEventListener('click', () => {
        sidebar.classList.add('is-userbar-open')
      })
  
      sidebarUserbarClose.addEventListener('click', () => {
        sidebar.classList.remove('is-userbar-open')
      })
    }
  },
})
