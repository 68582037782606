<template lang="pug">
  include ../../mixins
  validation-observer(
    tag="form"
    ref="observer"
    v-slot="{ errors }"
    @submit.prevent="validateBeforeSubmit()"
  )
    +b.g-row.--appearance_spaced.--space_xl
      +b.g-cell.g-cols.--12-xs.--6-sm
        d-control-input(
          rules="required|username"
          type="text"
          :input-label="labels.name"
          v-model="formdata.name"
          name="name"
        )
      +b.g-cell.g-cols.--12-xs.--6-sm
        d-control-input(
          :rules="{ required: !formdata.phone || formdata.notificationMail, email: true }"
          type="email"
          inputmode="email"
          :input-label="labels.email"
          v-model="formdata.email"
          name="email"
        )
      +b.g-cell.g-cols.--12-xs
        d-control-input(
          :rules="{ required: !formdata.email || Boolean(formdata.phone) }"
          type="tel"
          inputmode="tel"
          :input-label="labels.phone"
          v-model="formdata.phone"
          mask="+38(###)###-##-##"
          name="phone"
        )
      +b.g-cell.g-cols.--12-xs
        d-control-textarea(
          v-model="formdata.content"
          rules="required"
          type="text"
          name="content"
          input-class="d-control-input--height_3"
          :input-label="labels.content"
        )
      +b.g-cell.g-cols.--12-xs
        +b.g-row.--appearance_spaced.--space_xl.--space_2xl-xl.--align_center.--justify_between
          +b.g-cell.g-cols.--12-xs.--auto-md
            +b.LABEL.control-checkbox.--variant_flex
              +e.INPUT.element(
                v-model="formdata.notificationMail"
                type="checkbox"
                name="notificationMail"
              )
              +e.SPAN.label
              +e.content
                +b.P.ds-caption.--size_xs.--size_sm-lg.--color_grey {{ _("Повідомляти про відповіді на пошту") }}
          +b.g-cell.g-cols.--12-xs.--narrow-md
            +b.BUTTON.control-button(
              @click.prevent="validateBeforeSubmit()"
              :disabled="isLoad"
            )
              +e.element
                +e.SPAN.text {{ _('Надіслати') }}
</template>

<script>
import { mapState } from 'vuex'
import FormMixin from '@mixins/form.mixin.js'
import { addQuestionResource } from '@services/review.service.js'

export default {
  mixins: [FormMixin],

  props: {
    productId: {
      type: [String, Number],
    },
  },

  data() {
    return {
      formdata: {
        name: '',
        email: '',
        phone: '',
        content: '',
        product: this.productId,
        notificationMail: false,
      },
      isAuth: window.isAuthenticated,
      labels: {
        name: this._('Ваше ім`я'),
        email: this._('Ваш Email'),
        phone: this._('Ваш телефон'),
        content: this._('Ваше питання'),
      },
    }
  },

  computed: {
    ...mapState('user', ['user']),
  },

  watch: {
    user: {
      deep: true,
      immediate: true,
      handler(nval) {
        if (this.isAuth) {
          this.formdata.name = `${nval.firstName} ${nval.lastName}`
          this.formdata.email = nval.email
          this.formdata.phone = nval.phone ? `+${nval.phone}` : ''
        }
      },
    },
  },

  methods: {
    prepareFormdata() {
      const formdata = JSON.parse(JSON.stringify(this.formdata))

      return formdata
    },

    send() {
      const formdata = this.prepareFormdata()

      return addQuestionResource.execute({}, formdata).then(res => {
        this.handleResponse({ modal: true, res })

        setTimeout(() => {
          this.$emit('close')
        }, 200)
      })
    },
  },
}
</script>
