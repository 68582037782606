<template lang="pug">
  include ../mixins
  vue-countdown(
    v-slot="{ days, hours, minutes, seconds }"
    :time="time"
    :transform="transformSlotProps"
  )
    +b.P.ds-caption.--size_xxl.--size_3xxl-xl.--weight_semi-bold.--appearance_center {{ days }}:{{ hours }}:{{ minutes }}:{{ seconds }}
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown'

export default {
  components: {
    VueCountdown,
  },

  props: {
    time: Number,
  },

  methods: {
    transformSlotProps(props) {
      const formattedProps = {}

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = 10 > value ? `0${value}` : String(value)
      })

      return formattedProps
    },
  },
}
</script>
