import CreateForm from './CreateForm'
import CommentForm from './CommentForm'
import QuestionForm from './QuestionForm'
import CreateWrapper from './CreateWrapper'
import Card from './Card'

export default function install(Vue) {
  Vue.component('product-review-create-form', CreateForm)
  Vue.component('product-review-comment-form', CommentForm)
  Vue.component('product-review-question-form', QuestionForm)
  Vue.component('product-review-create-wrapper', CreateWrapper)
  Vue.component('product-review-card', Card)
}
