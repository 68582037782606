export default {
  goods: {
    ru: ['товар', 'товара', 'товаров'],
    uk: ['товар', 'товари', 'товарів'],
    en: ['item', 'items', 'items'],
  },
  month: {
    ru: ['месяц', 'месяца', 'месяцев'],
    uk: ['місяць', 'місяці', 'місяців'],
    en: ['month', 'months', 'months'],
  },
  payment: {
    ru: ['платеж', 'платежа', 'платежей'],
    uk: ['платіж', 'платежi', 'платежів'],
    en: ['payment', 'payments', 'payments'],
  },
}
