import dictionary from './dictionary'

const lang = window.language
export function handleCase(amount, word, lastNumber) {
  const [first, second, third] = dictionary[word][lang]
  if (1 === amount) return first
  if (1 < amount && 5 > amount) return second
  if (10 <= amount && 20 >= amount) return third
  if (20 < amount && 1 === lastNumber) return first
  if (20 < amount && 1 < lastNumber && 5 > lastNumber) return second
  if (20 < amount && (5 <= lastNumber || 9 >= lastNumber || 0 === lastNumber)) return third
  return third
}

export default {
  bind(element, binding) {
    const amount = binding.value
    const word = binding.arg
    const lastNumberOfAmount = Number(String(amount).split('')[String(amount).split('').length - 1])
    element.innerHTML = handleCase(amount, word, lastNumberOfAmount)
  },
}
