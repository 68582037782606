<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { turnComparisonResource } from '@services/comparison.service.js'

export default {
  props: {
    isActive: {},
    itemId: {},
    categoryId: {},
  },

  data() {
    return {
      isActiveProduct: this.isActive,
    }
  },

  computed: {
    ...mapState('compare', [
      'lastAction',
      'compareInfo',
    ]),
  },

  watch: {
    compareInfo: {
      deep: true,
      handler(nval) {
        const hasCategoryInCompare = nval.items.find(item => item.id === Number(this.categoryId))

        if (nval.isDeterminated && !hasCategoryInCompare && Number(this.categoryId)) {
          this.isActiveProduct = false
        }
      },
    },

    lastAction: {
      deep: true,
      handler(nval) {
        if (Number(this.itemId) === Number(nval.added)) {
          this.isActiveProduct = true
        }

        if (Number(this.itemId) === Number(nval.removed)) {
          this.isActiveProduct = false
        }
      },
    },
  },

  methods: {
    ...mapActions('compare', [
      'getCompareData',
    ]),

    ...mapMutations('compare', [
      'SET_REMOVED_ID',
      'SET_ADDED_ID',
    ]),

    async send() {
      const formdata = { idProduct: this.itemId }

      const { data: { item } } = await turnComparisonResource.execute({}, formdata)

      this.isActiveProduct = item.create

      if (this.isActiveProduct) {
        this.SET_ADDED_ID(this.itemId)
        this.SET_REMOVED_ID(null)
      } else {
        this.SET_REMOVED_ID(this.itemId)
        this.SET_ADDED_ID(null)
      }

      this.showNotification()

      this.getCompareData()
    },

    showNotification() {
      if (this.isActiveProduct) {
        this.$notify({
          title: this._('Додано до порівняння'),
          type: 'success',
        })
      } else {
        this.$notify({
          title: this._('Видалено з порівняння'),
          type: 'success',
        })
      }
    },
  },

  render() {
    return this.$scopedSlots.default({
      action: this.send,
      isActive: this.isActiveProduct,
    })
  },
}
</script>
