<template lang="pug">
  include ../mixins
  +b.d-control-input(:class="inputClass")
    component(
      v-if="inputLabel"
      :is="labelComponent",
      :id="id",
      :value="value",
      :input-label="inputLabel",
      :is-required="isRequired"
      :hint="hint"
      :is-bound="isBoundLabel || isFocusedMaskField"
    )
    +b.d-control-input__wrapper
      component.d-control-input__element(
        v-if='mask'
        v-mask="{ mask: mask, placeholder: '_', clearMaskOnLostFocus: true, showMaskOnHover: false }"
        :is="element",
        :value.prop='value',
        ref='input'
        v-on="listeners"
        v-bind='$attrs'
        :type='type'
        v-model='model',
        :id="id"
        @focus="isFocusedMaskField = true"
        @blur="isFocusedMaskField = false"
      )
      component.d-control-input__element(
        v-else
        :is="element",
        :value.prop='value',
        ref='input'
        v-on="listeners"
        v-bind='$attrs'
        :type='type'
        v-model='model',
        :id="id"
      )
        template(
          v-for="(name, key) in eSlots"
          v-slot:[name]="binded"
        )
          slot(
            v-bind="binded"
            :name="key"
          )
    +b.d-control-input__icon.--append.is-interactive(
      v-if="type === 'password'"
      @click.prevent='changeType()'
    )
      icon-eye-on(v-if="show")
      icon-eye-off(v-else)
</template>

<script>
const eSlotsResolver = (acc, key) => {
  acc[key] = key
  return acc
}

const getESlots = keys => keys
  .reduce(eSlotsResolver, {})

export default {
  name: 'element-input',
  props: [
    'id',
    'value',
    'inputLabel',
    'labelComponent',
    'type',
    'element',
    'inputClass',
    'mask',
    'hint',
    'rules',
    'isBoundLabel',
  ],

  data() {
    return {
      input: '',
      show: false,
      isFocusedMaskField: false,
    }
  },

  watch: {
    value: {
      handler() {
        this.input = this.value
      },
      deep: true,
    },
  },

  computed: {
    eSlots() {
      return getESlots(Object.keys(this.$scopedSlots))
    },

    listeners() {
      return {
        ...this.$listeners,
        input: $event => {
          this.$emit('input', this.getEvent($event))
        },
      }
    },

    model: {
      get() {
        return this.input
      },
      set(e) {
        this.input = e
      },
    },

    isRequired() {
      if (typeof this.rules === 'object' && this.rules !== null) {
        return this.rules?.required || false
      }

      const r = this.rules || ''

      return r.includes('required')
    },
  },

  mounted() {
    this.input = this.value
  },

  methods: {
    changeType() {
      this.show = !this.show

      if (this.show) {
        this.$refs.input.type = 'text'
      } else {
        this.$refs.input.type = 'password'
      }
    },

    getEvent(event) {
      if (event && event.type) {
        return event.target.value
      }

      return event
    },
  },
}
</script>
