import { prettifyPhone } from '@utils/transformers'

export const prettifyPrice = p => (p).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0')

export const install = Vue => {
  Vue.filter('prettifyPhone', prettifyPhone)
  Vue.filter('prettifyPrice', prettifyPrice)
}

export default { install }
