<template lang="pug">
  include ../mixins
  +b.ripple-container(@click="createRipple")
    slot
    span(
      v-for="ripple in ripples"
      :key="ripple.id"
      :style="ripple.style"
      class="ripple"
    )
</template>

<script>
export default {
  data() {
    return {
      ripples: [],
    }
  },

  methods: {
    createRipple(event) {
      const container = event.currentTarget
      const rect = container.getBoundingClientRect()
      const size = Math.max(rect.width, rect.height)
      const x = event.clientX - rect.left - size / 2
      const y = event.clientY - rect.top - size / 2

      const newRipple = {
        id: Date.now(),
        style: {
          width: `${size}px`,
          height: `${size}px`,
          top: `${y}px`,
          left: `${x}px`,
        },
      }

      this.ripples.push(newRipple)

      setTimeout(() => {
        this.ripples = this.ripples.filter(ripple => ripple.id !== newRipple.id)
      }, 600)
    },
  },
}
</script>
