<template lang="pug">
  include ../mixins
  ui-dropdown(v-slot="{ isActive, toggle, hide }")
    ui-click-outside(:do="hide")
      +b.share-toggler
        +e.item
          +b.single-icon.--variant_circle.--variant_5(
            @click.prevent="toggle"
          )
            icon-share
        +e.content(v-if="isActive")
          ui-share(
            :url="url"
            :title="title"
          )
</template>

<script>
export default {
  props: {
    url: String,
    title: String,
  },
}
</script>
