<template lang="pug">
include ../mixins
validation-observer.relative(
  tag="form"
  ref="observer"
  v-slot="{ errors }"
  @submit.prevent="validateBeforeSubmit()"
)
  ui-preloader(v-if="isLoad")
  +b.g-row.--appearance_spaced.--space_xl.--space_3xl-xl
    +b.g-cell.g-cols.--12-xs
      d-control-input(
        rules="email_phone|required"
        type="text"
        :input-label="_('Телефон / E-mail')"
        v-model="formdata.emailPhone"
        name="emailPhone"
      )
    +b.g-cell.g-cols--12-xs
      +b.BUTTON.control-button(
        @click.prevent="validateBeforeSubmit()"
        :disabled="isLoad"
      )
        +e.element
          +e.SPAN.text {{ _('Відправити') }}
    validation-provider(
      slim
      v-slot="{ errors }"
      name="nonFieldErrors"
    )
      +b.g-cell.g-cols.--12-xs(v-if="errors[0]")
        +b.P.error-label {{ errors[0] }}
</template>

<script>
import { reestablishResource } from '@services/auth.service.js'
import FormMixin from '@mixins/form.mixin.js'
import qs from 'qs'

export default {
  mixins: [FormMixin],

  props: {
    nextUrl: {
      type: String,
    },
  },

  data() {
    return {
      formdata: {
        emailPhone: '',
      },
    }
  },

  methods: {
    send(data) {
      return reestablishResource.execute({}, data).then(res => {
        const { data: { item } } = res

        const params = {
          user_token: item.userToken,
          id_user: item.idUser,
        }

        const query = qs.stringify(params)

        window.location = `${this.nextUrl}?${query}`
      })
    },
  },
}
</script>
