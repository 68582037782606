<template lang="pug">
  include ../mixins
  div
    ui-preloader(v-if="isLoad")
    +b.ds-panel.--space_2xl.--space_6xl-xl
      +e.element.--offset_bottom
        +b.service-table-select
          d-control-multiselect(
            v-model="city"
            track-by="label"
            label="title"
            name="city"
            :options="citiesArray"
            :allow-empty="true"
            :placeholder="labels.city"
            :input-label="city ? labels.city : ''"
            :searchable="true"
            @input="filterServices()"
          )
            template(#noResult)
              span {{ _("Елементи не знайдено") }}
            template(#noOptions)
              span {{ _("Список порожній") }}
    +b.TABLE.service-table
      +e.TR.row
        +e.TH.head(v-for="attr in tableHead")
          +b.P.ds-caption.--size_2xs.--weight_medium {{ attr }}
      template(v-for="item in filteredTableData")
        +e.TR.row
          +e.TD.data.--city(colspan="4")
            +b.P.ds-caption.--size_md.--weight_semi-bold.--weight_medium-lg {{ item.city }}
        +e.TR.row(
          v-for="service in item.services"
          :class="{ 'service-table__row--variant_border': item.services.length > 1 }"
        )
          +e.TD.data(:data-label="_('Адреса')")
            +e.content
              +b.P.ds-caption.--size_xs.--size_2xs-xl.--color_main {{ service.address }}
          +e.TD.data(:data-label="_('Назва центру')")
            +e.content
              +b.P.ds-caption.--size_xs.--size_2xs-xl {{ service.center }}
          +e.TD.data(:data-label="_('Графік роботи')")
            +e.content
              +b.P.ds-caption.--size_xs.--size_2xs-xl {{ service.schedule }}
          +e.TD.data(:data-label="_('Телефон')")
            +e.content
              +b.g-row.--appearance_spaced.--space_lg
                +b.g-cell.g-cols.--narrow-xs.separated-items(
                  v-for="item in service.phones"
                )
                  +b.A.ds-link.--size_xs.--size_2xs-xl.--color_dark.--inline(
                    :href="'tel:'+item.phone"
                  ) {{ item.phone }}
</template>

<script>
import {
  serviceCitiesRecource,
  serviceListRecource,
} from '@services/common.service.js'

export default {
  data() {
    return {
      tableHead: [
        this._('Місто і адреса'),
        this._('Назва центру'),
        this._('Графік роботи'),
        this._('Телефон'),
      ],
      citiesArray: [],
      city: '',
      tableData: [],
      filteredTableData: [],
      isLoad: false,
      labels: {
        city: this._('Mісто'),
      },
    }
  },

  async mounted() {
    this.isLoad = true

    await this.getCities()
    await this.getServices()

    this.isLoad = false

    this.filteredTableData = this.tableData
  },

  methods: {
    async getCities() {
      const { data } = await serviceCitiesRecource.execute()

      this.citiesArray = data.items
    },

    async getServices() {
      const { data } = await serviceListRecource.execute()

      this.tableData = data.items
    },

    filterServices() {
      if (null === this.city) this.filteredTableData = this.tableData
      else this.filteredTableData = this.tableData.filter(el => el.label === this.city.label)
    },
  },
}
</script>
