<template lang="pug">
  include ../mixins
  +b.catalog-view
    ui-preloader(v-if="isLoad")
    +b.ui-tabs
      +e.heading.--variant_2.--variant_5
        +e.item.--variant_1.--variant_3(
          v-for="tab in tabs"
          :class="{ 'is-active': tab.value === currentTab }"
          @click.prevent="selectTab(tab)"
        )
          span {{ tab.label }}
          |&nbsp;
          +b.SPAN.ds-caption.--color_dark {{ tab.count }}
      +e.content
        +b.g-row.--space_xl.--appearance_spaced(v-if="response.items.length")
          +b.g-cell.g-cols.--12-xs(v-for="item in response.items")
            product-review-card(:item="item")
        +b.card.--variant_2(v-else)
          +b.g-row.--space_xl.--appearance_spaced.--justify_between
            +b.g-cell.g-cols.--12-xs.--6-lg
              +b.P.ds-caption.--size_2sm.--size_md-xl.--weight_medium(
                v-if="currentTab === 'review'"
              ) {{ _("Наразі відгуків немає") }}
              +b.P.ds-caption.--size_2sm.--size_md-xl.--weight_medium(
                v-if="currentTab === 'question'"
              ) {{ _("Наразі запитань немає") }}
    +b.ds-panel.--space_4xl(v-if="!response.pagination.last")
      +e.element.--offset_top
        +b.g-row
          +b.g-cell.g-cols.--12-xs.--narrow-sm
            +b.control-button.--variant_bordered.--space_3(
              @click.prevent="loadMore"
              ref="loadMore"
            )
              +e.element
                +e.SPAN.content {{ _("Показати ще") }}
</template>

<script>
import CatalogMixin from '@mixins/catalog.mixin.js'
import {
  reviewListAllResource,
  reviewListResource,
  reviewListQuestionResource,
} from '@services/review.service.js'

const RESOURCES_MAP = {
  ALL: 'all',
  REVIEW: 'review',
  QUESTION: 'question',
}

const RESOURCES = {
  [RESOURCES_MAP.ALL]: reviewListAllResource,
  [RESOURCES_MAP.REVIEW]: reviewListResource,
  [RESOURCES_MAP.QUESTION]: reviewListQuestionResource,
}

export default {
  components: {
    'filter-section': () => import('./FilterSection'),
    pagination: () => import('@app/UiElements/Pagination'),
  },

  mixins: [
    CatalogMixin,
  ],

  props: {
    productId: {
      type: String,
    },
    countAll: {
      type: [Number, String],
    },
    countReview: {
      type: [Number, String],
    },
    countQuestion: {
      type: [Number, String],
    },
  },

  data() {
    return {
      currentTab: RESOURCES_MAP.ALL,
      tabs: [
        { label: this._('Всі'), value: RESOURCES_MAP.ALL, count: this.countAll },
        { label: this._('Відгуки'), value: RESOURCES_MAP.REVIEW, count: this.countReview },
        { label: this._('Питання'), value: RESOURCES_MAP.QUESTION, count: this.countQuestion },
      ],
      selectedFilters: {},
      response: {
        items: [],
        pagination: {},
      },
      currentPage: 1,
    }
  },

  computed: {
    hasNext() {
      return Boolean(this.response.next)
    },
  },

  mounted() {
    this.getCards()
  },

  methods: {
    selectTab(item) {
      this.currentTab = item.value

      this.currentPage = 1

      this.getCards(this.currentPage, 'update')
    },

    prepareParams(page) {
      return {
        productId: this.productId,
        page,
      }
    },

    async getCards(page = 1, trigger) {
      this.isLoad = true

      const params = this.prepareParams(page)

      const { data } = await RESOURCES[this.currentTab].execute(params)

      this.response.pagination = data.pagination

      if (trigger === 'update') {
        this.response.items = data.items
      } else {
        this.response.items.push(...data.items)
      }

      this.isLoad = false
    },

    loadMore() {
      this.$refs.loadMore.blur()

      this.currentPage += 1

      this.getCards(this.currentPage)
    },
  },
}
</script>
