import { saveAs } from 'file-saver'

/**
 * @param {string} resource
 * @param {object} params
 */
export default async function exportFile(resource, params = {}) {
  const response = await resource.execute({}, params)
  const blob = await response.blob()
  const disposition = response.headers.get('Content-Disposition')
  const fileName = disposition.match(new RegExp('"([^;]*)"'))[1]

  const file = window.URL.createObjectURL(blob)

  saveAs(file, fileName)
}
