export function disableAnimation() {
  const { body } = document
  let enableAnimation
  const xl = 1200
  const isDesktop = window.innerWidth >= xl
  if (isDesktop) {
    window.addEventListener('scroll', () => {
      clearTimeout(enableAnimation)
      if (!body.classList.contains('remove-hover')) body.classList.add('remove-hover')
      enableAnimation = setTimeout(() => {
        body.classList.remove('remove-hover')
      }, 200)
    })
  }
}
