<template lang="pug">
include ../mixins
validation-observer.relative(
  tag="form"
  ref="observer"
  v-slot="{ errors }"
  @submit.prevent="validateBeforeSubmit()"
)
  ui-preloader(v-if="isLoad")
  +b.g-row.--appearance_spaced.--space_xl.--space_3xl-xl
    +b.g-cell.g-cols.--12-xs
      d-control-input(
        rules="required"
        type="text"
        v-model="formdata.secretCode"
        name="secretCode"
        :input-label="_('Введіть перевірочний код')"
      )
    +b.g-cell.g-cols.--12-xs
      +b.BUTTON.control-button(
        @click.prevent="validateBeforeSubmit()"
        :disabled="isLoad"
      )
        +e.element
          +e.SPAN.text {{ _('Відновити') }}
    validation-provider(
      slim
      v-slot="{ errors }"
      name="nonFieldErrors"
    )
      +b.g-cell.g-cols.--12-xs(v-if="errors[0]")
        +b.P.error-label {{ errors[0] }}
</template>

<script>
import qs from 'qs'
import FormMixin from '@mixins/form.mixin.js'
import { passwordCodeResource } from '@services/auth.service.js'

export default {
  mixins: [FormMixin],

  props: {
    nextUrl: {
      type: String,
    },
  },

  data() {
    return {
      formdata: {
        secretCode: '',
      },
    }
  },

  created() {
    this.getQueryParams()
  },

  methods: {
    getQueryParams() {
      const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })

      this.formdata.userToken = params.user_token
      this.formdata.idUser = params.id_user
    },

    send(data) {
      return passwordCodeResource.execute({}, data).then(res => {
        const { data: { item } } = res

        const params = {
          user_token: item.userToken,
          id_user: item.idUser,
          secret_code: this.formdata.secretCode,
        }

        const query = qs.stringify(params)

        window.location = `${this.nextUrl}?${query}`
      })
    },
  },
}
</script>
