import {
  postResource,
  createResource,
  getResource,
} from '@resource/resource'
import { CART } from './paths'

export const cartChangeResource = createResource(
  CART.CHANGE, postResource
)

export const cartClearResource = createResource(
  CART.CLEAR, postResource
)

export const cartListResource = createResource(
  CART.LIST, getResource
)

export const cartPromocodeResource = createResource(
  CART.PROMOCODE, postResource
)

export const cartCountResource = createResource(
  CART.COUNT, getResource
)
