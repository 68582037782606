<script>
import { mapState } from 'vuex'

export default {
  props: {
    count: String,
  },

  data() {
    return {
      totalCount: Number(this.count),
    }
  },

  computed: {
    ...mapState('compare', [
      'compareInfo',
    ]),

    calculatedTotal() {
      return this.compareInfo.items.reduce((acc, el) => {
        acc += el.count

        return acc
      }, 0)
    },
  },

  watch: {
    calculatedTotal: {
      handler(nval) {
        this.totalCount = nval
      },
    },
  },

  methods: {
    openModal() {
      const modal = () => import('@app/Modals/CompareView')

      this.$modal.show(modal, {}, {
        classes: ['vm-modal--size_xs'],
      })
    },
  },

  render() {
    return this.$scopedSlots.default({
      totalCount: this.totalCount,
      openModal: this.openModal,
    })
  },
}
</script>
