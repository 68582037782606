import qs from 'qs'
import { scrollUnlock, scrollLock } from '@utils/scroll'
import { addLang } from '@utils/urls'
import { restoreObject } from '@utils/utils'

export default {
  data() {
    return {
      isShowFilters: false,
      isLoad: false,
      filters: [],
    }
  },

  methods: {
    openFilter() {
      this.isShowFilters = true

      scrollLock()
    },

    closeFilter() {
      this.isShowFilters = false

      scrollUnlock()
    },

    pushHistory() {
      let baseUrl = `${addLang(window.location.origin)}/`

      if (this.slug) {
        baseUrl += `${this.slug}/`
      }

      if (this.category) {
        baseUrl += `${this.category}/`
      }

      let page = ''

      if (1 < this.paginationInfo.page) {
        page = `page/${this.paginationInfo.page}/`
      }

      const preparedUrl = `${baseUrl}${this.generateUrl(this.selectedFilters)}${page}`

      window.history.pushState(null, null, preparedUrl)
    },

    generateUrl(params) {
      let url = qs.stringify(params, {
        arrayFormat: 'comma',
        delimiter: ';',
      })

      if ('' !== url && !url.endsWith('/')) {
        url = `${url}/`
      }

      return decodeURIComponent(url)
    },

    removeEmpty(item) {
      Object.keys(item).forEach(el => {
        if (0 === item[el].length || '' === item[el][0] || null === item[el][0]) {
          delete item[el]
        }
      })
      return item
    },

    checkUrl(slug) {
      const pathElem = window.location.pathname.split('/')
      const params = 'page' !== pathElem[pathElem.indexOf(slug) + 1] ? pathElem[pathElem.indexOf(slug) + 1] : ''

      if (params) {
        this.parseUrl(params)
      }
    },

    parseUrl(params) {
      const paramsString = params
      const paramsArray = paramsString.split(';')
      const obj = {}

      paramsArray.forEach(el => {
        const valuesArray = el.split('=')[1].split(',')

        obj[el.split('=')[0]] = valuesArray
      })

      if (obj.price_min && obj.price_min) {
        obj.price = [obj.price_min[0], obj.price_max[0]]
      }

      this.selectedFilters = obj

      if (this.selectedFilters.sort) {
        this.sortValue = restoreObject(this.sortArray, 'value', this.selectedFilters.sort[0])
      }
    },
  },
}
