export function getCookie(name) {
  const replacedName = name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')
  const matches = document.cookie.match(
    new RegExp(`(?:^|; )${replacedName}=([^;]*)`)
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

export function setCookie(name, val, expires = 604800) {
  const cookie = [name, '=', val, ';max-age=', expires, '; path=/;'].join('')
  document.cookie = cookie
}

export function eraseCookie(name) {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}
