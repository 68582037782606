<template lang="pug">
include ../mixins
+b.P.ds-caption.--size_xs.--size_sm-xl.--lh_8
  span {{ text }}
  | 
  +b.A.ds-link.--color_main.--size_xs.--size_sm-xl.--inline(
    :href="policyPageUrl"
    target="_blank"
  ) {{ _("політикою конфіденційності") }}
  | 
  | {{ _("та") }}
  | 
  +b.A.ds-link.--color_main.--size_xs.--size_sm-xl.--inline(
    :href="rulesPageUrl"
    target="_blank"
  ) {{ _("правилами сайту") }}
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },
  },

  data() {
    return {
      policyPageUrl: window.policyPageUrl,
      rulesPageUrl: window.rulesPageUrl,
    }
  },
}
</script>
