<template lang="pug">
  include ../mixins
  validation-observer(
    tag="form"
    :action="completeSocial"
    method="POST"
    ref="observer"
    v-slot="{ errors }"
    @submit.prevent="validateBeforeSubmit()"
  )
    input(
      type="hidden"
      name="csrfmiddlewaretoken"
      :value="getCookie('csrftoken')"
    )
    input(
      type="hidden"
      name="userId"
      :value="codeData.idUser"
    )
    input(
      type="hidden"
      name="emailPhone"
      :value="codeData.emailPhone"
    )
    +b.g-row.--appearance_spaced.--space_xl.--space_3xl-xl
      +b.g-cell.g-cols.--12-xs
        d-control-input(
          rules="email_phone|required"
          type="text"
          v-model="formdata.emailPhone"
          :input-label="_('Е-mail або номер телефону')"
          name="emailPhone"
          :disabled="isCode && !canEdit"
        )
      +b.g-cell.g-cols.--12-xs(v-if="!canEdit")
        +b.SPAN.ds-link.--size_2xs.--color_main.--inline(
          @click="accessEditing"
        ) {{ _('Я помилився, ввести знову') }}
      +b.g-cell.g-cols.--12-xs(v-if="isCode && !canEdit")
        code-form(
          ref="code"
          :formdata="codeData.secretCode"
          @update="codeData.secretCode = $event"
        )
      +b.g-cell.g-cols.--12-xs
        +b.BUTTON.control-button(
          v-if="!isCode && canEdit"
          @click.prevent="validateBeforeSubmit()"
          :disabled="isLoad"
        )
          +e.element
            +e.SPAN.text {{ _('Відправити') }}
        +b.BUTTON.control-button(
          v-else
          @click.prevent="validateBeforeSubmit(sendCode)"
          :disabled="isLoad"
        )
          +e.element
            +e.SPAN.text {{ _('Підтвердити') }}
      validation-provider(
        slim
        v-slot="{ errors }"
        name="nonFieldErrors"
      )
        +b.g-cell.g-cols.--12-xs(v-if="errors[0]")
          +b.P.error-label {{ errors[0] }}
</template>

<script>
import {
  socialEmailPhoneResource,
} from '@services/auth.service.js'
import FormMixin from '@mixins/form.mixin.js'
import { getCookie } from '@utils/cookie'

export default {
  mixins: [FormMixin],

  components: {
    'code-form': () => import('@app/Forms/CodeForm'),
  },

  props: {
    redirectLink: String,
    completeSocial: String,
    partialToken: String,
  },

  data() {
    return {
      formdata: {
        emailPhone: '',
      },
      codeData: {
        secretCode: '',
        emailPhone: '',
        idUser: '',
      },
      isCode: false, // false
      canEdit: true, // -true
    }
  },

  methods: {
    getCookie,

    send(data) {
      return socialEmailPhoneResource.execute({}, data).then(res => {
        const { item } = res.data

        this.codeData.emailPhone = item.emailPhone
        this.codeData.idUser = item.idUser

        this.isCode = true
        this.canEdit = false
      })
    },

    sendCode() {
      this.$refs.observer.$el.submit()
    },

    accessEditing() {
      this.canEdit = true
      this.isCode = false
      this.codeData.secretCode = ''
    },

    scrollIntoView(item) {
      setTimeout(() => {
        item.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        })
      }, 1000)
    },
  },
}
</script>
