<template lang="pug">
  include ../mixins
  div.position--relative
    ui-preloader(v-if="isLoad" loader-color='loader-wrapper__bg--light')
    template(v-if="isResponse == true")
      +b.configurator-grid.ma--bottom_sm
        +b.configurator.position--relative.configurator-grid__left(
          v-for="(item, key, index) in configuratorData.products"
          :class="'configurator-grid__left--'+key"
        )
          configurator-product-list(
            :card-index="index"
            :selected-state="selectedState"
            :configurator-data="configuratorData.products"
            :current-data="item"
            :section="key"
            :fixed-data="fixedData"
            :is-load="isLoad"
            @change="handleProductSelection($event)"
            @delete="handleDelete($event)"
            @delete-group="deleteGroup($event)"
            @open-modal="openModal($event)"
          )
        +b.configurator__preview.card--color_white.--variant_shadow-lg.configurator-grid__right.u-display--hidden.--block-md(
          v-for="(item, key, index) in configuratorData.products"
          :class="'configurator-grid__right--'+key"
        )
          //- bem-text.ma--bottom_2xs.u-display_block.u-display--hidden-lg(
          //-   v-if="index === 0"
          //-   tag="p"
          //-   size="3sm"
          //-   :variant="['center', 'bold']"
          //- ) {{_('Ваш набор')}}
          configurator-selected.card--color_white(
            :selected-state="selectedState"
            :configurator-data="configuratorData.products"
            :object-item="objectItem"
            :current-data="item"
            :section="key"
            :fixed="fixedData"
            @fix-key="handleFixedRequest($event)"
            @open-modal="openModal($event)"
          )
      row.sticky-configurator(
        justify="end" space="sm-lg" v-if="selectedIds.length !== 0"
      )
        cell(cols="12 4-xl")
          +b.card--color_white.--space_3xs.--space_2sm-lg
            row
              cell(cols="12 6-xxl")
                row(justify="between")
                  cell(cols="6 12-xl")
                    +b.align-height
                      bem-text.ma--bottom_2xs.ma--bottom_3xs-xl(
                        tag="p"
                        color="black"
                        size="3xs"
                      ) {{_('сума без знижки')}}
                      bem-text.ma--bottom_2xs.ma--bottom_3sm-xl(
                        tag="p"
                        color="black"
                        variant="bold"
                        size="md"
                      ) {{priceSection.totalWithoutDiscount}} {{_('грн')}}
                  cell.ma--bottom_2xs.ma--bottom_sm-xl(cols="6 12-xl")
                    +b.inliner--start
                      +b.ICON-PERCENT.i-icon.--size_lg.--color_accent-2
                      div.ma--left_2xs
                        bem-text.ma--bottom_2xs.ma--bottom_xs-xl(
                          tag="p"
                          color="black"
                          size="3xs"
                        ) {{_('Загальна сума комплекту:')}}
                        bem-text(
                          tag="p"
                          color="accent-2"
                          variant="bold"
                          size="md"
                        ) {{priceSection.total}} {{_('грн')}}
                  cell.ma--bottom_2xs-till-xxl(cols="12")
                    +b.discount-card
                      row(justify="between" align="center")
                        cell
                          row(align="center" space="xs")
                            cell(cols="narrow")
                              +b.ICON-ROUND-CHECK.i-icon.--size_4sm.--color_green
                            cell(cols="auto narrow-xl")
                              row(space="xs")
                                cell
                                  +b.SPAN.text.--color_black.--height_sm.--size_2xs.--size_3xs-xl.u-display--hidden.--block-lg {{_('Ваша')}} &nbsp;
                                cell
                                  +b.SPAN.text.--color_black.--height_sm.--size_2xs.--size_3xs-xl {{_('вигода:')}} &nbsp;
                        cell
                          bem-text.configurator__price(
                            tag="p"
                            color="black"
                            variant="bold"
                          ) {{ priceSection.discount }} {{_('грн')}}
              cell(cols="12 6-xxl")
                row(justify="between")
                  cell.ma--bottom_2xs(cols="12 6-sm 12-xl")
                    buy-action(
                      :product-id="selectedIds"
                      :object-item="objectItem"
                    )
                      template(#default="{ handle }")
                        bem-button(
                          variant="border-accent-1"
                          space="custom-3"
                          width="full"
                          height="custom"
                          @event="handle"
                        )
                          row(align="center" justify="center")
                            bem-text.configurator__price(
                              tag="span"
                              variant="bold"
                            ) {{_('Замовити')}} &nbsp;
                              bem-text.configurator__price.u-display--hidden-till-xl(
                                tag="span"
                                variant="bold"
                              ) {{_('комплект')}}
                            +b.ICON-CART.i-icon.--size_md.--color_main.ma--left_xs
                  cell(
                    v-if="priceSection.isPayParts"
                    cols="12 6-sm 12-xl"
                  )
                    modal-handler(
                      component-name="credit-form"
                      modal-class="modal modal--size_xl modal--full-container modal--padding_md modal--border_sm"
                      :object-item="{ productId: selectedIds, price: priceSection.total, maxPayCount: priceSection.maxParts }"
                    )
                      template(#default="{ handler }")
                        +b.button.--variant_border-accent-3.--width_full.--space_custom-3.--height_custom-2.BUTTON(@click="handler")
                          bem-text.configurator__price(
                            tag="span"
                            variant="bold"
                          ) {{ _('Купити в кредит') }}
</template>

<script>
import {
  configuratorDetailResource,
  configuratorSelectResource,
  turnConfiguratorResource,
  configuratorDeleteGroupResource,
} from '@services/configurator.service.js'

export default {
  components: {
    'configurator-product-list': () => import('./ConfiguratorProductList'),
    'configurator-selected': () => import('./ConfiguratorSelected'),
  },
  props: {
    objectItem: Object,
  },
  data() {
    return {
      configuratorData: {},
      selectedState: {
        hoods: {},
        hobs: {},
        ovens: {},
        fourth: {},
      },
      fixedData: [],
      isResponse: false,
      priceSection: {},
      selectedIds: [],
      isLoad: false,
    }
  },
  watch: {
    selectedState: {
      handler(nval) {
        this.getSelectedIds(nval)
      },
      deep: true,
    },
  },
  mounted() {
    this.getConfigurator()
  },
  methods: {
    handleProductSelection(data) {
      this.selectedState[data.type] = data.cardIndex
      this.selectedState[data.type].isSelected = true
      this.sendSelectedProduct(data.type)
      // if (!window.matchMedia('(min-width: 1024px)').matches) {
      //   this.openModal(data.type)
      // }
    },
    handleDelete(id) {
      return turnConfiguratorResource.execute({}, { idProd: id })
        .then(res => {
          this.priceSection = res.data.item
          this.getConfigurator()
          Object.keys(this.configuratorData.products).forEach(key => {
            if (this.configuratorData.products[key].cards.length) {
              const hasId = this.configuratorData.products[key].cards.some(card => card.id === id)
              if (hasId) this.selectedState[key] = {}
            }
          })
        })
    },
    getConfigurator() {
      this.isLoad = true
      return configuratorDetailResource.execute()
        .then(async res => {
          await this.checkSelected(res.data.item)
          this.configuratorData = res.data.item
          this.priceSection = res.data.item.price
          this.isResponse = true
          this.isLoad = false
        })
    },
    sendSelectedProduct(key) {
      return configuratorSelectResource.execute({}, {
        idProd: this.selectedState[key].id,
        typeLevel: key,
        isLock: this.selectedState[key].isLock,
      }).then(res => {
        const { item } = res.data
        this.priceSection = item
      })
    },
    checkSelected(data) {
      Object.keys(data.products).forEach(key => {
        data.products[key].cards.forEach(card => {
          if (true === card.isSelected) this.selectedState[key] = card
          if (true === card.isLock) { this.fixedData.push(key) }
        })
      })
    },
    handleFixedRequest(key) {
      this.selectedState[key].isLock = !this.selectedState[key].isLock
      this.sendSelectedProduct(key)
      const one = 1
      if (this.fixedData.includes(key)) {
        const index = this.fixedData.indexOf(key)
        this.fixedData.splice(index, one)
      } else {
        this.fixedData.push(key)
      }
    },
    deleteGroup(group) {
      this.isLoad = true
      return configuratorDeleteGroupResource.execute({}, { typeLevel: group })
        .then(res => {
          this.isLoad = false
          this.priceSection = res.data.item
          this.getConfigurator()
          this.selectedState[group] = {}
        }).catch(() => {
          this.isLoad = false
        })
    },
    getSelectedIds(selected) {
      this.selectedIds = []
      Object.keys(selected).forEach(key => {
        if (selected[key]?.id) this.selectedIds.push(selected[key].id)
      })
    },
    openModal(section) {
      this.$modal.show(() => import('./ConfiguratorModal'), {
        selectedState: this.selectedState,
        objectItem: this.objectItem,
        section,
        fixed: this.fixedData,
        fixedFunction: this.handleFixedRequest,
      },
      {
        classes: 'modal modal--container modal--size_sm modal--border_sm modal--padding_custom-sm',
      })
    },
  },
}
</script>
