<template lang="pug">
  include ../mixins
  vue-star-rating(
    @rating-selected="setRating"
    :read-only="readOnly"
    :increment="increment"
    :show-rating="false"
    :rating="rating"
    active-color="#FFA500"
    inactive-color="#F0F2F5"
    :padding="padding"
    :star-points="[14.015, 1.53027, 15.7194, 1.53027, 19.4953, 8.22934, 20.8274, 9.19716, 28.3654, 10.7181, 28.8921, 12.3391, 23.6877, 18.0003, 23.1789, 19.5663, 24.0617, 27.2053, 22.6828, 28.2072, 15.6905, 25.0069, 14.0439, 25.0069, 7.05155, 28.2072, 5.67264, 27.2053, 6.55551, 19.5663, 6.04669, 18.0003, 0.84231, 12.3391, 1.369, 10.7181, 8.90701, 9.19716, 10.2391, 8.22934, 14.015, 1.53027]"
  )
</template>

<script>
import VueStarRating from 'vue-star-rating'

export default {
  components: {
    VueStarRating,
  },

  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    currentRating: {
      type: Number,
      default: 0,
    },
    increment: {
      default: 1,
    },
    padding: {
      default: 2,
    },
  },

  data() {
    return {
      rating: null,
    }
  },

  watch: {
    currentRating: {
      immediate: true,
      handler(nval) {
        this.rating = nval
      },
    },
  },

  methods: {
    setRating(rating) {
      this.$emit('input', rating)
    },
  },
}
</script>
