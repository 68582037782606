import Vue from 'vue'

Vue.directive('reviews-anchor', {
  bind: node => {
    const SECTION_OFFSET = 20

    node.addEventListener('click', () => {
      const allSections = [...document.querySelectorAll('.js-product-section')]
      const section = allSections.find(s => s.getAttribute('data-section') === 'reviews')

      const headerHeight = document.querySelector('.header').clientHeight
      const navigationHeight = document.querySelector('.js-product-navigation').clientHeight
      const sectionTopOffset = section.getBoundingClientRect().top
      const totalOffset = (sectionTopOffset - navigationHeight - headerHeight - SECTION_OFFSET) + window.scrollY

      window.scrollTo({ top: totalOffset })
    })
  },
})
