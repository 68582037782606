import { compareCategoriesResource } from '@services/comparison.service.js'

export const compare = {
  namespaced: true,

  state: {
    compareInfo: {
      items: [],
      isDeterminated: false,
    },
    lastAction: {
      added: null,
      removed: null,
    },
  },

  actions: {
    getCompareData({ commit }) {
      return compareCategoriesResource.execute().then(res => {
        const { data } = res

        commit('CHANGE_COMPARE_STATE', data)
      })
    },
  },

  mutations: {
    CHANGE_COMPARE_STATE(state, payload) {
      state.compareInfo = { ...payload, isDeterminated: true }
    },

    SET_REMOVED_ID(state, payload) {
      state.lastAction.removed = payload
    },

    SET_ADDED_ID(state, payload) {
      state.lastAction.added = payload
    },
  },
}
