import Products from './Products'
import Payments from './Payments'
import ContentLogos from './ContentLogos'
import ImageGrid from './ImageGrid'

export default function install(Vue) {
  Vue.component('slider-products', Products)
  Vue.component('slider-payments', Payments)
  Vue.component('slider-content-logos', ContentLogos)
  Vue.component('slider-image-grid', ImageGrid)
}
