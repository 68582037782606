<template lang="pug">
span.error-label
  slot
</template>
<script>
export default {
  props: {
    classes: {},
  },
}
</script>
