<template lang="pug">
  include ../mixins
  validation-observer.relative(
    tag="form"
    ref="observer"
    v-slot="{ errors }"
    @submit.prevent=""
  )
    ui-preloader(v-if="isLoad")
    +b.g-row.--appearance_spaced.--space_xl.--space_3xl-xl.--justify_end
      +b.g-cell.g-cols.--12-xs(
        :class="{ 'g-cols--6-sm': optimized }"
      )
        d-control-input(
          autocomplete="off"
          rules="phone|required"
          type="text"
          :input-label="_('Номер телефону')"
          mask="+38 (###) ###-##-##"
          v-model="formdata.phone"
          name="phone"
          @input="validatePhone"
        )
      +b.g-cell.g-cols.--12-xs(
        v-if="confirmationInfo.determinated && phoneIsValid"
        :class="{ 'g-cols--6-sm': optimized }"
      )
        +b.BUTTON.control-button(
          @click.prevent="openConfirmationModal"
          :disabled="isLoad"
        )
          +e.element
            +e.SPAN.text(v-if="confirmationInfo.userExists") {{ _('Увійти') }}
            +e.SPAN.text(v-else) {{ _('Зареєструватись') }}
      +b.g-cell.g-cols.--12-xs(
        v-else
        :class="{ 'g-cols--6-sm': optimized }"
      )
        +b.BUTTON.control-button(
          @click.prevent="validateForm"
          :disabled="isLoad"
        )
          +e.element
            +e.SPAN.text {{ _('Продовжити') }}
      +b.g-cell.g-cols.--12-xs
        +b.g-row.--justify_center
          +b.g-cell
            +b.SPAN.ds-link.--size_sm.--color_main.--inline(
              @click.prevent="changeAuthMethod"
            ) {{ _("Увійти за логіном та паролем") }}
      validation-provider(
        slim
        v-slot="{ errors }"
        name="nonFieldErrors"
      )
        +b.g-cell.g-cols.--12-xs(v-if="errors[0]")
          +b.P.error-label {{ errors[0] }}
</template>

<script>
import {
  checkUserExistsResource,
  loginByPhoneResource,
} from '@services/auth.service.js'
import { clearPhone } from '@utils/transformers'
import LoginConfirm from '@app/Modals/Authentication/LoginConfirm'

export default {
  props: {
    needReload: {
      type: Boolean,
    },
    optimized: {
      type: Boolean,
    },
  },

  data() {
    return {
      formdata: {
        phone: '',
      },
      isLoad: false,
      confirmationInfo: {
        determinated: false,
        userExists: false,
      },
    }
  },

  computed: {
    phoneIsValid() {
      const token = this.formdata.phone

      return 12 === token.replace(/\D/g, '').toString().length
    },
  },

  methods: {
    changeAuthMethod() {
      this.$emit('method:change', 'email')
    },

    validateForm() {
      this.$refs.observer.validate()
    },

    validatePhone() {
      this.confirmationInfo = {
        determinated: false,
        userExists: false,
      }

      if (!this.phoneIsValid) return

      this.isLoad = true

      const formdata = {
        phone: clearPhone(this.formdata.phone),
      }

      checkUserExistsResource.execute({}, formdata).then(res => {
        const { data: { item } } = res

        this.confirmationInfo = {
          determinated: true,
          userExists: item.userExists,
        }
      }).finally(() => {
        this.isLoad = false
      })
    },

    openConfirmationModal() {
      this.$modal.show(LoginConfirm, {
        info: {
          token: clearPhone(this.formdata.phone),
          callback: this.codeConfirmationHandler,
        },
      })
    },

    codeConfirmationHandler(res) {
      const formdata = { idToken: res.id }

      loginByPhoneResource.execute({}, formdata).then(() => {
        if (this.needReload) {
          window.location.reload()
        } else {
          const redirect = this.getRedirectUrl()

          if (redirect) {
            window.location = redirect
          } else {
            window.location = window.indexPageUrl
          }
        }
      })
    },

    getRedirectUrl() {
      const params = new URLSearchParams(window.location.search)
      const redirect = params.get('next')

      return redirect
    },
  },
}
</script>
