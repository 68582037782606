<script>
import { getVideoId, getVideoPreview } from '@utils/video'

export default {
  props: {
    url: {
      type: String,
    },
    preview: {
      type: String,
    },
  },

  data() {
    return {
      videoLink: this.getVideo(),
    }
  },

  computed: {
    localPreview() {
      const videoId = getVideoId(this.url)

      return this.preview || getVideoPreview(videoId)
    },
  },

  methods: {
    getVideo() {
      const item = `//www.youtube.com/embed/${getVideoId(this.url)}?autoplay=1`

      return item
    },
  },

  render() {
    return this.$scopedSlots.default({
      preview: this.localPreview,
      videoLink: this.videoLink,
    })
  },
}
</script>
