export function hasKey(obj, key) {
  return Object.prototype.hasOwnProperty.call(obj, key)
}

export function valueFromSelect(item, key) {
  let res
  if (Array.isArray(item)) {
    res = []
    item.forEach(el => {
      res.push(el[key])
    })
  } else {
    res = item[key]
  }
  return res
}

export function restoreObject(arr, key, value) {
  let item
  if (Array.isArray(value)) {
    item = []
    value.forEach(el => {
      item.push(arr.filter(itemEl => itemEl[key] === el))
    })
  } else {
    item = arr.filter(itemEl => itemEl[key] === value)
  }
  return item
}

export function removeEmptyKeys(obj) {
  Object.keys(obj).forEach(key => {
    if ('' === obj[key] || 0 === obj[key]) delete obj[key]
  })
  return obj
}

export function simplePluralize(n, textForms) {
  /* eslint-disable */
  const n1 = Number(n)
  const n2 = Number(n1) % 10
  if (n1 > 10 && n1 < 20) return textForms[2]
  if (n2 > 1 && n2 < 5) return textForms[1]
  if (n2 == 1) return textForms[0]
  return textForms[2]
}

export function isChromeOnWindows() {
  const userAgent = navigator.userAgent.toLowerCase()
  const isChrome = /chrome/.test(userAgent) && !/edge|edg|opr|opera|brave/.test(userAgent)
  const isWindows = /windows/.test(userAgent)

  return isChrome && isWindows
}

export const getTimeToEndOfDay = () => {
  const d = new Date()
  const h = d.getHours()
  const m = d.getMinutes()
  const s = d.getSeconds()
  const secondsUntilEndOfDate = (24 * 60 * 60) - (h * 60 * 60) - (m * 60) - s

  return secondsUntilEndOfDate
}