<template lang="pug">
  include ../../mixins
  div
    vue-slider(
      v-model="internal"
      class="vue-slider--variant_default"
      :clickable="false"
      :drag-on-click="true"
      transition="0.2"
      :dot-size="12"
      :height="5"
      :min="min"
      :max="max"
      :contained="true"
      :interval="interval"
      @drag-end="update"
      @dragging="dragHandler"
    )
    +b.ds-panel.--space_md.--space_2xl
      +e.element.--offset_top
        +b.g-row.--align_center
          +b.g-cell.g-cols.--auto-xs
            +b.range-field
              +e.control
                d-control-input(
                  v-model="localInternal[0]"
                  input-class="d-control-input--variant_2"
                  :is-bound-label="true"
                  name="min"
                  type="number"
                  :input-label="_('От')"
                  @input="debounceUpdate"
                )
              +e.SPAN.symbol {{ _("₴") }}
          +b.g-cell.g-cols.--narrow-xs
            +b.P.ds-caption.--size_lg.--color_relief-13 —
          +b.g-cell.g-cols.--auto-xs
            +b.range-field
              +e.control
                d-control-input(
                  v-model="localInternal[1]"
                  input-class="d-control-input--variant_2"
                  :is-bound-label="true"
                  name="max"
                  type="number"
                  :input-label="_('До')"
                  @input="debounceUpdate"
                )
              +e.SPAN.symbol {{ _("₴") }}
</template>

<script>
import VueSlider from 'vue-slider-component'
import { debounce } from 'lodash'

/* eslint-disable */
const gcd = (a, b, onZero = 1) => (
  a < b
    ? gcd(b, a, onZero)
    : (
      b < 0.001 || isNaN(b) || isNaN(b)
        ? (0 === b ? onZero : a)
        : gcd(b, a - Math.floor(a / b) * b)
    )
)

const decimalCount = number => {
  const numberAsString = number.toString()
  if (numberAsString.includes('.')) {
    return numberAsString.split('.')[1].length
  }
  return 0
}

export default {
  components: {
    VueSlider,
  },

  props: ['value', 'filter'],

  data() {
    return {
      internal: [],
      localInternal: [],
      interval: 1,
      min: 0,
      max: 1,
      debounceUpdate: () => {},
    }
  },

  watch: {
    filter: {
      deep: true,
      immediate: true,
      handler: 'clearMinMax',
    },
    value: {
      immediate: true,
      handler(nval) {
        if (nval) {
          this.updateInternal()
        }
      },
    },
  },

  mounted() {
    this.debounceUpdate = debounce(this.updateInput, 700)
  },

  methods: {
    reset() {
      this.internal = [this.min, this.max]
      this.localInternal = [this.min, this.max]
    },

    clearMinMax() {
      const { min, max } = this.filter.options[0]

      this.min = parseFloat(Math.max(min || 0).toFixed(2))
      this.max = parseFloat(Math.min(max || 0).toFixed(2))

      const isInteger = Number.isInteger(this.min) && Number.isInteger(this.max)

      this.interval = parseFloat(gcd(Math.abs(this.min), Math.abs(this.max)).toFixed(2))

      if (this.interval === 1 && !isInteger) {
        const minDecimal = decimalCount(this.min)
        const maxDecimal = decimalCount(this.max)
        const exponent = minDecimal > maxDecimal ? minDecimal : maxDecimal
        const d = 1 / Math.pow(10, exponent)
        this.interval = parseFloat((d).toFixed(exponent))
      }

      this.updateInternal()
    },

    updateInternal(value = this.value) {
      this.internal = this.normalize(value)

      this.localInternal = this.normalize(value)
    },

    update() {
      const value = this.clear(this.internal)

      this.$nextTick(() => {
        this.$set(this.localInternal, 0, value[0] || this.min)
        this.$set(this.localInternal, 1, value[1] || this.max)
      })

      this.input(value)
    },

    input(value) {
      this.$emit('input', value)
    },

    clear(value) {
      const [min, max] = this.normalize([value[0], value[1]])

      if (min === this.min && max === this.max) {
        return []
      }

      return [min, max]
    },

    normalize(value) {
      if (!value) {
        return [this.min, this.max]
      }

      let [min, max] = value

      return [min || this.min, max || this.max]
    },

    updateInput() {
      if (!this.localInternal[0]) {
        this.localInternal[0] = this.min
      }

      if (this.localInternal[0] < this.min) {
        this.$set(this.internal, 0, this.min)

        this.localInternal[1] = this.min
      } else {
        this.$set(this.internal, 0, this.localInternal[0])
      }

      if (!this.localInternal[1]) {
        this.localInternal[1] = this.max
      }

      if (this.localInternal[1] > this.max) {
        this.$set(this.internal, 1, this.max)

        this.localInternal[1] = this.max
      } else {
        this.$set(this.internal, 1, this.localInternal[1])
      }

      this.update()
    },

    dragHandler(e) {
      this.localInternal[0] = e[0]
      this.localInternal[1] = e[1]
    },
  },
};
</script>
