<template lang="pug">
include ../mixins
validation-observer.relative(
  tag="form"
  ref="observer"
  v-slot="{ errors }"
  @submit.prevent="validateBeforeSubmit()"
)
  ui-preloader(v-if="isLoad")
  +b.email-confirm
    +e.part
      +b.g-row.--appearance_spaced.--space_xl.--space_3xl-xl
        +b.g-cell.g-cols.--12-xs
          d-control-input(
            rules="required"
            type="text"
            v-model="formdata.secretCode"
            name="secretCode"
            :input-label="_('Введіть перевірочний код')"
          )
          +b.ds-panel.--space_sm.--space_md-lg
            +e.element.--offset_top
              +b.P.ds-caption.--size_xs.--size_sm-lg(v-if="resendBlocked") {{ _("Запросити код повторно можна через") }}
                |&nbsp;
                b {{ time }}
                |&nbsp;
                span {{ _("секунд") }}
              +b.SPAN.ds-link.--size_2xs.--size_sm-lg.--color_blue.--inline(
                v-else
                @click.prevent="resendCode"
              ) {{ _("Запросити код повторно") }}
    +e.part
      +b.g-row.--appearance_spaced.--space_2xl
        +b.g-cell.g-cols.--12-xs
          authentication-privacy-policy(
            :text="_('Авторизуючись на сайті Ви погоджуєтеся з')"
          )
        +b.g-cell.g-cols.--12-xs
          +b.BUTTON.control-button(
            @click.prevent="validateBeforeSubmit()"
            :disabled="isLoad"
          )
            +e.element
              +e.SPAN.text {{ _('Підтвердити') }}
        validation-provider(
          slim
          v-slot="{ errors }"
          name="nonFieldErrors"
        )
          +b.g-cell.g-cols.--12-xs(v-if="errors[0]")
            +b.P.error-label {{ errors[0] }}
</template>

<script>
import qs from 'qs'
import {
  repeatCodeResource,
  registrationActivateResource,
} from '@services/auth.service.js'
import FormMixin from '@mixins/form.mixin.js'
import TimerMixin from '@app/Authentication/TimerMixin'
import { setCookie } from '@utils/cookie'

export default {
  mixins: [
    FormMixin,
    TimerMixin,
  ],

  data() {
    return {
      formdata: {
        secretCode: '',
      },
      params: {},
    }
  },

  created() {
    this.getQueryParams()
  },

  methods: {
    getQueryParams() {
      this.params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    },

    resendCode() {
      const formdata = { emailPhone: this.params.email_phone }

      repeatCodeResource.execute({}, formdata).then(() => {
        this.notification(this._('Код відправлено'))

        setCookie('code_send_at', new Date().getTime())

        clearInterval(this.timeInterval)

        this.startTimer()
      })
    },

    send() {
      const formdata = {
        emailPhone: this.params.email_phone,
        idUser: this.params.id_user,
        secretCode: this.formdata.secretCode,
      }

      return registrationActivateResource.execute({}, formdata).then(() => {
        if (this.params.next) {
          window.location = this.params.next
        } else {
          window.location = window.indexPageUrl
        }
      })
    },
  },
}
</script>
