import PrivacyPolicy from './PrivacyPolicy'
import Login from './Login'
import LoginEmail from './LoginEmail'
// TODO: Remove Registration
import Registration from './Registration'
// TODO: Remove RegistrationConfirm
import RegistrationConfirm from './RegistrationConfirm'
import PasswordForgot from './PasswordForgot'
import PasswordForgotConfirm from './PasswordForgotConfirm'
import PasswordSet from './PasswordSet'
import EmailNotSet from './EmailNotSet'
import SocialNetworks from './SocialNetworks'
import Controller from './Controller'

export default function install(Vue) {
  Vue.component('authentication-privacy-policy', PrivacyPolicy)
  Vue.component('authentication-login', Login)
  Vue.component('authentication-login-email', LoginEmail)
  // TODO: Remove Registration
  Vue.component('authentication-registration', Registration)
  // TODO: Remove RegistrationConfirm
  Vue.component('authentication-registration-confirm', RegistrationConfirm)
  Vue.component('authentication-password-forgot', PasswordForgot)
  Vue.component('authentication-password-forgot-confirm', PasswordForgotConfirm)
  Vue.component('authentication-password-set', PasswordSet)
  Vue.component('authentication-email-not-set', EmailNotSet)
  Vue.component('authentication-social-networks', SocialNetworks)
  Vue.component('authentication-controller', Controller)
}
