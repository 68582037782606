<template lang="pug">
include ../mixins
+b.product-card-compare
  +e.wrapper
    +e.action-wrapper
      +e.action(@click="removeItem")
        icon-trash
      favorite-action(
        v-if="isAuthenticated"
        :is-active="item.isFavorite"
        :item-id="item.id"
      )
        template(#default="{ action, isActive }")
          +e.action.--variant_heart(
            :class="{ 'is-active': isActive }"
            @click.prevent="action"
          )
            icon-heart-filled(v-if="isActive")
            icon-heart-empty(v-else)
      favorite-forbidden(v-else)
        template(#default="{ openForbiddenModal }")
          +e.action.--variant_heart(
            @click.prevent="openForbiddenModal"
          )
            icon-heart-empty
    +e.A.image-wrapper(:href="item.link")
      picture(v-if="item.imgCommon")
        source(
          type="image/webp"
          :srcset="item.imgWebp"
        )
        +e.IMG.image(
          :src="item.imgCommon"
        )
      +e.IMG.image(
        v-else
        :src="productPlaceholder"
      )
    +e.content
      +b.line-clamp.--line_3
        +b.A.ds-link.--color_dark.--size_2xxs.--size_sm-xl.--weight_medium(
          :href="item.link"
        ) {{ item.title }}
  +e.footer(v-if="item.isAvailable")
    +b.g-row.--justify_between.--align_end.--appearance_spaced
      +b.g-cell.g-cols.--auto-xs
        template(v-if="item.price1")
          +b.P.ds-caption.--lh_1
            +b.SPAN.ds-caption.--size_2xxs.--size_md-xl.--appearance_through-till-xl.--appearance_diagonal-xl.--color_relief-5 {{ item.price1|prettifyPrice }}
            |&nbsp;
            +b.SPAN.ds-caption.--size_2xxs.--size_2xs-xl.--color_relief-5
              span.is-visible-inline-sm {{ _("грн") }}
              span.is-hidden-sm {{ _("₴") }}
          +b.P.ds-caption.--lh_1
            +b.SPAN.ds-caption.--size_lg.--size_2xl-xl.--color_red.--weight_semi-bold {{ item.price2|prettifyPrice }}
            |&nbsp;
            +b.SPAN.ds-caption.--size_xs.--size_md-xl.--color_red.--weight_semi-bold {{ _("грн") }}
        +b.P.ds-caption.--lh_1(v-else)
          +b.SPAN.ds-caption.--size_lg.--size_2xl-xl.--weight_semi-bold {{ item.price2|prettifyPrice }}
          |&nbsp;
          +b.SPAN.ds-caption.--size_xs.--size_md-xl.--weight_semi-bold {{ _("грн") }}
      +b.g-cell.g-cols.--narrow-xs
        buy-action(
          :product-id="item.id"
          :is-in-cart="item.addedToCart"
        )
          template(#default="{ handle, isInCart }")
            +b.single-icon.--variant_2.--variant_6(
              @click.prevent="handle"
              :class="isInCart ? 'single-icon--size_4' : 'single-icon--size_2'"
            )
              icon-cart-added(v-if="isInCart")
              icon-cart(v-else)
  +e.footer(v-else)
    track-action(
      :product-id="item.id"
      :is-active="item.isTracker"
      @update="$emit('update:track')"
      #default="{ addTrack, deleteTrack, isActive }"
    )
      +b.SPAN.ds-link.--color_main.--size_xxs.--size_xs-sm.--size_sm-xl.--inline.--underline(
        v-if="isActive"
        @click.prevent="deleteTrack"
      )
        +e.element
          +e.SPAN.content {{ _("Перестати слідкувати") }}
      +b.SPAN.ds-link.--color_main.--size_xxs.--size_xs-sm.--size_sm-xl.--inline.--underline(
        v-else
        @click.prevent="addTrack"
      )
        +e.element
          +e.SPAN.content {{ _("Слідкувати за наявністю") }}
</template>

<script>
import { openConfirmModal } from '@utils/submit'

export default {
  props: {
    item: {
      type: Object,
    },
  },

  data() {
    return {
      isAuthenticated: window.isAuthenticated,
    }
  },

  methods: {
    async removeItem() {
      const title = this._('Ви впевнені, що хочете видалити товар із порівняння?')

      await openConfirmModal({ title }, this)

      this.$emit('remove')
    },
  },
}
</script>
