<template lang="pug">
  include ../mixins
  +b.catalog-view
    ui-preloader(v-if="isLoad")
    +e.heading.--variant_2
      +e.total
        +b.P.ds-caption.--size_sm.--size_lg-xl
          | {{ _("Знайдено") }}
          | 
          b {{ paginationInfo.count }}
          |&nbsp;
          span {{ pluralizedProductWord }}
      +e.toggler
        +b.filter-button(@click.prevent="openFilter")
          +e.icon
            icon-filter
          +e.content
            +b.SPAN.ds-caption.--size_sm.--color_main {{ _("Фільтри") }}
    +e.main
      +e.filter(:class="{ 'is-active': isShowFilters }")
        +e.filter-wrapper
          +e.top
            +b.i-link.--size_sm(
              v-if="hasSelectedFilters"
              @click.prevent="clearFilters"
            )
              +e.icon.--offset_right.--color_dark
                icon-filter-clear
              +e.SPAN.text {{ _("Скинути фільтр") }}
            +b.SPAN.ds-caption.--size_lg.--weight_bold(v-else) {{ _("Фільтр") }}
            +e.close(@click.prevent="closeFilter")
              icon-close
          +b.ds-panel.--space_3xl.is-visible-xl
            +e.element.--offset_bottom
              +b.BUTTON.control-button.--variant_bordered.--icon_1(
                @click.prevent="clearFilters"
              )
                +e.element
                  +e.SPAN.text {{ _("Скинути фільтр") }}
                  +e.icon.--offset_left
                    icon-catalog
          filter-section(
            v-if="showFilters"
            ref="filter"
            :filters="filters"
            :selected-filters="selectedFilters"
            @input="filterChanged"
          )
          +e.footer
            +b.control-button(@click.prevent="closeFilter")
              +e.element
                +e.SPAN.text {{ _("Применить") }}
      +e.content(ref="content")
        renderer(
          v-if="response"
          :result="response"
          :class-render="listClass"
        )
        div(
          :class="listClass"
          v-else
        )
          slot(name="cards")
        pagination(
          v-if="paginationInfo.count > paginationInfo.per_page"
          :current="paginationInfo.page"
          :total="paginationInfo.count"
          :perPage="paginationInfo.per_page"
          :is-add-more="true"
          @changed="paginationChangeHandler"
        )
</template>

<script>
import { isEmpty } from 'lodash'
import CatalogMixin from '@mixins/catalog.mixin.js'
import { simplePluralize } from '@utils/utils'
import {
  searchCatalogResource,
  searchCatalogWithoutFiltersResource,
  searchFiltersResource,
} from '@services/common.service.js'
import { scrollUnlock, scrollLock } from '@utils/scroll'
import { scrollToElement } from '@utils/scrollToElement'

export default {
  components: {
    'filter-section': () => import('./FilterSection'),
    pagination: () => import('@app/UiElements/Pagination'),
  },

  mixins: [
    CatalogMixin,
  ],

  props: {
    pagination: Object,
  },

  data() {
    return {
      selectedFilters: {},
      response: '',
      filters: [],
      showFilters: false,
      paginationInfo: {
        count: this.pagination.count,
        page: this.pagination.page,
        per_page: this.pagination.perPage,
      },
      isAddPage: false,
      slug: 'search-result',
    }
  },

  computed: {
    pluralizedProductWord() {
      const variants = [
        this._('товар'),
        this._('товари'),
        this._('товарів'),
      ]
      return simplePluralize(this.paginationInfo.count, variants)
    },

    hasSelectedFilters() {
      const items = JSON.parse(JSON.stringify(this.selectedFilters))

      delete items.search

      return Object.values(items).some(el => !isEmpty(el))
    },

    listClass() {
      const arr = ['products-list']

      if (!this.paginationInfo.count) {
        arr.push('is-spaced')
      }

      return arr.join(' ')
    },
  },

  created() {
    window.onpopstate = async () => {
      window.location.reload()
    }
  },

  async mounted() {
    this.checkUrl(this.slug)

    await this.getFilters()

    this.prepareFilterData()
  },

  methods: {
    filterChanged(e) {
      this.selectedFilters = e

      this.getCards()
    },

    async getFilters() {
      const params = {
        search: decodeURIComponent(this.selectedFilters.search[0]),
      }

      const { data } = await searchFiltersResource.execute(params)

      this.filters = data.items
    },

    prepareParams(page) {
      this.selectedFilters = this.removeEmpty(JSON.parse(JSON.stringify(this.selectedFilters)))

      return {
        slug: this.category,
        filters: this.selectedFilters,
        page,
      }
    },

    async getCards(page = 1, needPushState = true) {
      if (this.isLoad) return

      this.isLoad = true

      const params = this.prepareParams(page)

      const resource = isEmpty(params.filters) ? searchCatalogWithoutFiltersResource : searchCatalogResource

      const res = await resource.execute(params)

      if (this.isAddPage) {
        this.response += res.data
      } else {
        this.response = res.data
      }

      this.paginationInfo = res.meta

      if (needPushState) {
        this.pushHistory()
      }

      this.isLoad = false
    },

    prepareFilterData() {
      // open filter sections
      Object.keys(this.selectedFilters).forEach(key => {
        this.filters.forEach(item => {
          if (key === item.label) {
            item.isOpen = true
          }
        })
      })

      if (this.selectedFilters.category) {
        this.selectedFilters.category.forEach(val => {
          this.filters.forEach(item => {
            item.options.forEach(option => {
              if (option.label === val) {
                item.isOpen = true
              }
            })
          })
        })
      }

      this.filters = this.filters.map(item => {
        item.setInitialValue = true

        return item
      })

      this.showFilters = true
    },

    clearFilters() {
      if (this.isLoad) return

      this.selectedFilters = { search: this.selectedFilters.search }
      this.isAddPage = false
      this.getCards()
    },

    async paginationChangeHandler(info) {
      this.isAddPage = info.isLoadMore

      this.paginationInfo.page = info.page

      scrollLock()

      if (!this.response && this.isAddPage) {
        await this.getCards(info.page - 1, false)
        await this.getCards(info.page)
      } else {
        await this.getCards(info.page)
      }

      scrollUnlock()

      if (!this.isAddPage) {
        scrollToElement(this.$refs.content)
      }

      this.isAddPage = false
    },
  },
}
</script>
