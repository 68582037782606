<template lang="pug">
  div
    include ../mixins
    vue-easy-lightbox(
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="handleHide"
    )
      template(v-slot:toolbar="{ toolbarMethods }")
    slot
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'

const header = document.querySelector('body')
export default {
  name: 'ui-lightbox',
  components: {
    VueEasyLightbox,
  },
  props: {
    imgs: [Array, String],
    lightboxClass: {
      type: String,
      default: 'gallery-button',
    },
  },
  data() {
    return {
      visible: false,
      index: 0,
    }
  },
  mounted() {
    this.addListeners()
  },
  methods: {
    addListeners() {
      const wrapper = document.getElementsByClassName(this.lightboxClass)
      if (wrapper) {
        wrapper.forEach(el => {
          el.addEventListener('click', () => {
            const index = el.dataset.img
            this.show(Number(index))
          })
        })
      }
    },
    show(index) {
      this.visible = true
      this.index = index
      header.style.overflow = 'hidden'
    },
    handleHide() {
      this.visible = false
      header.style.overflow = 'auto'
    },
  },
}
</script>
