<template lang="pug">
  include ../../mixins
  ui-tabs(
    #default="{ activate, activeItem }"
    :active="target"
  )
    +b.ui-tabs
      +e.heading.--variant_1
        +e.item.--variant_2(
          @click.prevent="activate('review')"
          :class="{ 'is-active': activeItem === 'review' }"
        )
          span {{ _("Залиште відгук") }}
        +e.item.--variant_2(
          @click.prevent="activate('question')"
          :class="{ 'is-active': activeItem === 'question' }"
        )
          span {{ _("Задати питання") }}
      +e.wrapper
        product-review-create-form(
          v-if="activeItem === 'review'"
          :product-id="productId"
          @close="$emit('close')"
        )
        product-review-question-form(
          v-if="activeItem === 'question'"
          :product-id="productId"
          @close="$emit('close')"
        )
</template>

<script>
export default {
  props: {
    productId: {
      type: [String, Number],
    },
    target: {
      type: String,
      default: 'review',
    },
  },
}
</script>
