<template>
  <div
    class="vue-zoomer"
    @mousedown="startDrag"
    @mouseup="stopDrag"
    @mouseleave="stopDrag"
    @mousemove="onDrag"
    @wheel="onWheel"
  >
    <div
      ref="imageWrapper"
      class="zoomer"
      :style="imageStyle"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      zoom: 1.3,
      minZoom: 1.3,
      maxZoom: 3,
      drag: false,
      imgPosX: 0,
      imgPosY: 0,
      startX: 0,
      startY: 0,
      containerWidth: 0,
      containerHeight: 0,
      imgWidth: 0,
      imgHeight: 0,
    }
  },
  computed: {
    imageStyle() {
      return {
        transform: `translate(${this.imgPosX}px, ${this.imgPosY}px) scale(${this.zoom})`,
        cursor: this.drag ? 'grabbing' : 'grab',
      }
    },
  },
  async mounted() {
    await this.waitForImageLoad()

    this.updateContainerDimensions()
    this.centerImage()
    
    window.addEventListener('resize', this.updateContainerDimensions)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateContainerDimensions)
  },
  methods: {
    waitForImageLoad() {
      return new Promise(resolve => {
        const img = this.$refs.imageWrapper.querySelector('img')
        if (img && img.complete) {
          resolve()
        } else if (img) {
          img.onload = () => resolve()
        }
      })
    },
    updateContainerDimensions() {
      const container = this.$el.getBoundingClientRect()

      this.containerWidth = container.width
      this.containerHeight = container.height

      const img = this.$refs.imageWrapper.querySelector('img')

      if (img) {
        this.imgWidth = img.offsetWidth
        this.imgHeight = img.offsetHeight
      }
    },
    centerImage() {
      const offsetX = (this.containerWidth - this.imgWidth) / 2
      const offsetY = (this.containerHeight - this.imgHeight) / 2

      this.imgPosX = offsetX
      this.imgPosY = offsetY
    },
    startDrag(event) {
      this.drag = true
      this.startX = event.clientX - this.imgPosX
      this.startY = event.clientY - this.imgPosY
    },
    stopDrag() {
      this.drag = false
    },
    onDrag(event) {
      if (!this.drag) return

      let newX = event.clientX - this.startX
      let newY = event.clientY - this.startY

      const bounds = this.getBounds()

      if (newX > bounds.maxX) newX = bounds.maxX
      if (newX < bounds.minX) newX = bounds.minX

      if (newY > bounds.maxY) newY = bounds.maxY
      if (newY < bounds.minY) newY = bounds.minY

      this.imgPosX = newX
      this.imgPosY = newY
    },
    onWheel(event) {
      event.preventDefault()

      const prevZoom = this.zoom
      const delta = event.deltaY > 0 ? -0.1 : 0.1
      let newZoom = this.zoom + delta

      if (newZoom < this.minZoom) newZoom = this.minZoom
      if (newZoom > this.maxZoom) newZoom = this.maxZoom

      const zoomFactor = newZoom / prevZoom

      const centerX = this.containerWidth / 2
      const centerY = this.containerHeight / 2

      this.imgPosX = centerX - (centerX - this.imgPosX) * zoomFactor
      this.imgPosY = centerY - (centerY - this.imgPosY) * zoomFactor

      this.zoom = newZoom

      this.correctImagePosition()
    },
    getBounds() {
      const scaledWidth = this.imgWidth * this.zoom
      const scaledHeight = this.imgHeight * this.zoom

      const w = this.containerWidth - scaledWidth
      const h = this.containerHeight - scaledHeight

      const minX = w - (this.imgWidth / 2)
      const maxX = (w / 2) * -1

      const minY = h - (this.imgHeight / 2)
      const maxY = (h / 2) * -1

      return { minX, maxX, minY, maxY }
    },
    correctImagePosition() {
      const bounds = this.getBounds()

      if (this.imgPosX > bounds.maxX) this.imgPosX = bounds.maxX
      if (this.imgPosX < bounds.minX) this.imgPosX = bounds.minX

      if (this.imgPosY > bounds.maxY) this.imgPosY = bounds.maxY
      if (this.imgPosY < bounds.minY) this.imgPosY = bounds.minY
    },
  },
}
</script>

<style scoped>
.zoomer {
  position: absolute;
  user-select: none;
}
</style>
