<template lang="pug">
  include ../../mixins
  validation-observer(
    tag="form"
    ref="observer"
    v-slot="{ errors }"
    @submit.prevent="validateBeforeSubmit()"
  )
    +b.g-row.--appearance_spaced.--space_xl
      +b.g-cell.g-cols.--12-xs
        +b.star-rating
          validation-provider(
            tag="div"
            v-slot="{ errors }"
            name="point"
            :rules="{ required: true }"
          )
            +b.g-row.--justify_between.--align_center
              +b.g-cell
                +b.P.ds-caption.--size_sm.--color_grey {{ _("Оцініть товар") }}
              +b.g-cell
                ui-star-rating.vue-star-rating--variant_border(
                  :read-only="false"
                  :padding="7"
                  :current-rating="formdata.point"
                  v-model="formdata.point"
                )
            +b.P.error-label(v-if="errors[0]") {{ errors[0] }}
      +b.g-cell.g-cols.--12-xs
        d-control-input(
          rules="max:255"
          type="text"
          :input-label="labels.advantages"
          v-model="formdata.advantages"
          name="advantages"
        )
      +b.g-cell.g-cols.--12-xs
        d-control-input(
          rules="max:255"
          type="text"
          :input-label="labels.flaws"
          v-model="formdata.flaws"
          name="flaws"
        )
      +b.g-cell.g-cols.--12-xs.--6-sm
        d-control-input(
          rules="required|username"
          type="text"
          :input-label="labels.name"
          v-model="formdata.name"
          name="name"
        )
      +b.g-cell.g-cols.--12-xs.--6-sm
        d-control-input(
          :rules="{ required: formdata.notificationMail, email: true }"
          type="email"
          inputmode="email"
          :input-label="labels.email"
          v-model="formdata.email"
          name="email"
        )
      +b.g-cell.g-cols.--12-xs
        d-control-textarea(
          v-model="formdata.content"
          rules="required"
          type="text"
          name="content"
          input-class="d-control-input--height_3"
          :input-label="labels.content"
        )
      +b.g-cell.g-cols.--12-xs
        ui-upload(
          v-model="formdata.images"
          :max-files="10"
          ref="uploader"
        )
          template(slot="content")
            +b.ds-panel.--space_sm
              +e.element.--offset_bottom
                +b.P.ds-caption.--size_3md.--weight_medium {{ _("Добавьте фото") }}
            +b.P.ds-caption.--size_xs.--color_grey.--lh_8
              span.is-visible-inline-xl {{ _("Перетягніть файли сюди або натисніть кнопку.") }}
              |&nbsp;
              span {{ _("Додайте до 10 зображень у форматах .jpg, .gif, .png, розмір файлу до 5 МБ") }}
      +b.g-cell.g-cols.--12-xs
        d-control-input(
          rules="max:255"
          type="text"
          :input-label="labels.videoUrl"
          v-model="formdata.videoUrl"
          name="videoUrl"
        )
      +b.g-cell.g-cols.--12-xs
        +b.g-row.--appearance_spaced.--space_xl.--space_2xl-xl.--align_center.--justify_between
          +b.g-cell.g-cols.--12-xs.--auto-md
            +b.LABEL.control-checkbox.--variant_flex
              +e.INPUT.element(
                v-model="formdata.notificationMail"
                type="checkbox"
                name="notificationMail"
              )
              +e.SPAN.label
              +e.content
                +b.P.ds-caption.--size_xs.--size_sm-lg.--color_grey {{ _("Повідомляти про відповіді на пошту") }}
          +b.g-cell.g-cols.--12-xs.--narrow-md
            +b.BUTTON.control-button(
              @click.prevent="validateBeforeSubmit()"
              :disabled="isLoad"
            )
              +e.element
                +e.SPAN.text {{ _("Надіслати") }}
</template>

<script>
import { mapState } from 'vuex'
import FormMixin from '@mixins/form.mixin.js'
import { addReviewResource } from '@services/review.service.js'

export default {
  mixins: [FormMixin],

  props: {
    productId: {
      type: [String, Number],
    },
  },

  components: {
    'ui-upload': () => import('@app/Controls/UiUpload'),
  },

  data() {
    return {
      formdata: {
        advantages: '',
        flaws: '',
        content: '',
        images: [],
        point: null,
        product: this.productId,
        notificationMail: false,
      },
      isAuth: window.isAuthenticated,
      labels: {
        advantages: this._('Переваги'),
        flaws: this._('Недоліки'),
        name: this._('Ваше ім`я'),
        email: this._('Ваш Email'),
        content: this._('Ваш відгук або питання'),
        videoUrl: this._('Посилання на відео з Youtube'),
      },
    }
  },

  computed: {
    ...mapState('user', ['user']),
  },

  watch: {
    user: {
      deep: true,
      immediate: true,
      handler(nval) {
        if (this.isAuth) {
          this.formdata.email = nval.email
          this.formdata.name = `${nval.firstName} ${nval.lastName}`
        }
      },
    },
  },

  methods: {
    prepareFormdata() {
      const formdata = JSON.parse(JSON.stringify(this.formdata))

      formdata.images = formdata.images.map(el => ({ src: el.data }))

      return formdata
    },

    send() {
      const formdata = this.prepareFormdata()

      return addReviewResource.execute({}, formdata).then(res => {
        this.handleResponse({ modal: true, res })

        setTimeout(() => {
          this.$emit('close')
        }, 200)
      })
    },
  },
}
</script>
