<script>
export default {
  props: {
    product: {},
  },

  methods: {
    addGtagClickEvent() {
      /* eslint-disable-next-line */
      gtag('event', 'select_content', {
        content_type: 'product',
        items: [this.product],
      })
    },
  },

  render() {
    return this.$scopedSlots.default({
      addGtagClickEvent: this.addGtagClickEvent,
    })
  },
}
</script>
