<template lang="pug">
include ../mixins
validation-observer.relative(
  tag="form"
  ref="observer"
  v-slot="{ errors }"
  @submit.prevent="validateBeforeSubmit()"
)
  ui-preloader(v-if="isLoad")
  +b.g-row.--appearance_spaced.--space_xl.--space_3xl-xl
    +b.g-cell.g-cols.--12-xs
      d-control-input(
        rules="required|min:8"
        ref='newPassword1'
        type="password"
        v-model="formdata.newPassword1"
        :input-label="_('Вкажіть новий пароль')"
        name="newPassword1"
      )
    +b.g-cell.g-cols.--12-xs
      d-control-input(
        rules="required|min:8|confirmed:newPassword1"
        type="password"
        v-model="formdata.newPassword2"
        :input-label="_('Вкажіть новий пароль ще раз')"
        name="newPassword2"
      )
    +b.g-cell.g-cols--12-xs
      +b.BUTTON.control-button(
        @click.prevent="validateBeforeSubmit()"
        :disabled="isLoad"
      )
        +e.element
          +e.SPAN.text {{ _('Зберегти') }}
    validation-provider(
      slim
      v-slot="{ errors }"
      name="nonFieldErrors"
    )
      +b.g-cell.g-cols.--12-xs(v-if="errors[0]")
        +b.P.error-label {{ errors[0] }}
</template>

<script>
import qs from 'qs'
import FormMixin from '@mixins/form.mixin.js'
import { setNewPasswordResource } from '@services/auth.service.js'

export default {
  mixins: [FormMixin],

  props: {
    nextUrl: {
      type: String,
    },
  },

  data() {
    return {
      formdata: {
        newPassword1: '',
        newPassword2: '',
      },
    }
  },

  created() {
    this.getQueryParams()
  },

  methods: {
    getQueryParams() {
      const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })

      this.formdata.userToken = params.user_token
      this.formdata.idUser = params.id_user
      this.formdata.secretCode = params.secret_code
    },

    send(data) {
      return setNewPasswordResource.execute({}, data).then(() => {
        window.location = this.nextUrl
      })
    },
  },
}
</script>
