import ProductsCatalog from './ProductsCatalog'
import SearchCatalog from './SearchCatalog'
import CommonCatalog from './CommonCatalog'
import ReviewsCatalog from './ReviewsCatalog'
import Renderer from './Renderer'

import Widgets from './widgets'

export default function install(Vue) {
  Vue.component('catalog-products', ProductsCatalog)
  Vue.component('catalog-search', SearchCatalog)
  Vue.component('catalog-common', CommonCatalog)
  Vue.component('catalog-reviews', ReviewsCatalog)
  Vue.component('renderer', Renderer)

  Vue.use(Widgets)
}
