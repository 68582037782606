import urlLib from 'x-url'

const one = 1
const two = 2

export const DEFAULT_LANGUAGE = 'uk'

export function clean(url) {
  return url.replace(/\/+/img, '/')
}

export function getRelative(url = '') {
  if (!url.startsWith('http')) {
    return url
  }

  const object = urlLib.parse(url)

  return object.path
}

export function getPrefixed(url, prefix = null) {
  if (!prefix) {
    return url
  }

  return clean(prefix + getRelative(url))
}

export function getLanguageCode(url = '') {
  const [lang] = getRelative(url)
    .replace(/^[\/]+|[\/]+$/g, '').split('/', one)

  if (!lang || lang.length > two) {
    return DEFAULT_LANGUAGE
  }

  return lang
}

export const CURRENT_LANGUAGE = window && window.location &&
  getLanguageCode(window.location.href) || DEFAULT_LANGUAGE

export function removeLanguage(path = '') {
  const currentLanguageCode = getLanguageCode(path)

  if (currentLanguageCode === DEFAULT_LANGUAGE) {
    return path
  }

  return path.slice(currentLanguageCode.length + one)
}

export function prefixLanguage(url = '', {
  language = CURRENT_LANGUAGE,
} = {}) {
  const path = removeLanguage(getRelative(url))

  if (!language || language === DEFAULT_LANGUAGE) {
    return path
  }

  return getPrefixed(path, `/${language}/`)
}

export function addLang(url) {
  const lang = CURRENT_LANGUAGE

  return DEFAULT_LANGUAGE !== lang ? `${url}/${lang}` : url
}
