<script>
import { mapState } from 'vuex'

export default {
  props: {
    count: String,
  },

  data() {
    return {
      totalCount: Number(this.count),
    }
  },

  computed: {
    ...mapState('favorite', [
      'favoriteInfo',
    ]),
  },

  watch: {
    favoriteInfo: {
      deep: true,
      handler(nval) {
        this.totalCount = nval.count
      },
    },
  },

  render() {
    return this.$scopedSlots.default({
      totalCount: this.totalCount,
    })
  },
}
</script>
