<template lang="pug">
  include ../mixins
  +b.modal.--variant_heading
    +b.close(@click="$emit('close')")
      icon-close
    +e.heading
      +b.P.ds-caption.--size_lg.--weight_semi-bold {{ info.title }}
    +e.content
      +b.g-row
        +b.g-cell.g-cols.--6-xs
          +b.control-button(@click.prevent="submit(false)")
            +e.element
              +e.SPAN.text {{ _("Ні") }}
        +b.g-cell.g-cols.--6-xs
          +b.control-button.--variant_bordered(@click.prevent="submit(true)")
            +e.element
              +e.SPAN.text {{ _("Так") }}
</template>

<script>
export default {
  props: {
    info: {},
    promise: {},
  },

  methods: {
    submit(trigger) {
      this.promise(trigger)

      this.$emit('close')
    },
  },
}
</script>
