<template lang="pug">
include ../mixins
+b.product-card-small
  +e.wrapper
    +e.A.image-wrapper(:href="item.link")
      picture
        +e.IMG.image(
          v-if="item.img"
          :src="item.img"
        )
        +e.IMG.image(
          v-else
          :src="productPlaceholder"
        )
    +e.content
      +b.A.ds-link.--color_dark.--size_xs.--size_sm-xl.--weight_medium(
        :href="item.link"
      ) {{ item.title }}
  +e.footer
    +b.g-row.--justify_between.--align_end.--appearance_spaced
      +b.g-cell.g-cols.--auto-xs
        template(v-if="item.prices.price1")
          p
            +b.SPAN.ds-caption.--size_2sm.--size_md-xl.--appearance_through.--color_relief-5 {{ item.prices.price1|prettifyPrice }}
            |&nbsp;
            +b.SPAN.ds-caption.--size_xxs.--size_2xs-xl.--color_relief-5 {{ _("грн") }}
          p
            +b.SPAN.ds-caption.--size_lg.--size_2xl-xl.--color_red.--weight_semi-bold {{ item.prices.price2|prettifyPrice }}
            |&nbsp;
            +b.SPAN.ds-caption.--size_2xs.--color_red.--weight_semi-bold {{ _("грн") }}
        p(v-else)
          +b.SPAN.ds-caption.--size_lg.--size_2xl-xl.--weight_semi-bold {{ item.prices.price2|prettifyPrice }}
          |&nbsp;
          +b.SPAN.ds-caption.--size_2xs.--size_md-xl.--weight_semi-bold {{ _("грн") }}
      +b.g-cell.g-cols.--narrow-xs(v-if="item.isAvailable")
        buy-action(
          :product-id="item.id"
          :is-in-cart="item.addedToCart"
          @cart:update="$emit('cart:update')"
        )
          template(#default="{ handle, isInCart }")
            +b.single-icon.--variant_2.--variant_6(
              @click.prevent="handle(false)"
              :class="isInCart ? 'single-icon--size_4' : 'single-icon--size_2'"
            )
              icon-cart-added(v-if="isInCart")
              icon-cart(v-else)
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
  },

  data() {
    return {
      productPlaceholder: window.productPlaceholder,
    }
  },
}
</script>
