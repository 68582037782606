import Vue from 'vue'

Vue.directive('product-navigation', {
  bind: node => {
    const windowHash = window.location.hash.replace('#', '')

    const product = node.querySelector('.js-short-product')
    const items = [...node.querySelectorAll('.js-product-navigation-item')]

    const toggleProductInfo = () => {
      /* eslint-disable radix */
      const stickyTop = parseInt(window.getComputedStyle(node).top)
      const productOrderWrapper = document.querySelector('.js-product-order')
      const orderWrapperTop = productOrderWrapper.getBoundingClientRect().top
      const navigationHeight = node.clientHeight

      if (stickyTop >= (orderWrapperTop + navigationHeight)) {
        product.classList.add('is-active')
      } else {
        product.classList.remove('is-active')
      }
    }

    const checkIsInViewport = section => {
      const rect = section.getBoundingClientRect()
      const windowHeight = (window.innerHeight || document.documentElement.clientHeight)
      const vertInView = (rect.top <= windowHeight / 3.5) && ((rect.top + rect.height) >= 0)

      return vertInView
    }

    const highlightItems = () => {
      const IS_DESKTOP = 992 <= window.innerWidth
      const allSections = [...document.querySelectorAll('.js-product-section')]
      const desktopSections = allSections.filter(section => !section.getAttribute('data-section').includes('mobile'))
      const mobileSections = allSections.filter(section => !section.getAttribute('data-section').includes('desktop'))
      const sections = IS_DESKTOP ? desktopSections : mobileSections

      sections.forEach(section => {
        if (checkIsInViewport(section)) {
          items.forEach(item => {
            item.classList.remove('is-active')

            const targetSection = item.getAttribute('data-target')
            const targetSectionMobile = item.getAttribute('data-target-mobile')
            const target = IS_DESKTOP ? targetSection : (targetSectionMobile || targetSection)

            if (target === section.getAttribute('data-section')) {
              item.classList.add('is-active')

              node.scrollTo({ left: item.offsetLeft })
            }
          })
        }
      })
    }

    window.addEventListener('scroll', () => {
      highlightItems()

      if (product) {
        toggleProductInfo()
      }
    })

    const clickHandler = item => {
      const IS_DESKTOP = 992 <= window.innerWidth

      const targetSection = item.getAttribute('data-target')
      const targetSectionMobile = item.getAttribute('data-target-mobile')
      const target = IS_DESKTOP ? targetSection : (targetSectionMobile || targetSection)
      const sections = [...document.querySelectorAll('.js-product-section')]

      const section = sections.find(s => s.getAttribute('data-section') === target)

      const SECTION_OFFSET = 20

      const headerHeight = document.querySelector('.header').clientHeight
      const navigationHeight = node.clientHeight
      const sectionTopOffset = section.getBoundingClientRect().top
      const totalOffset = (sectionTopOffset - navigationHeight - headerHeight - SECTION_OFFSET) + window.scrollY

      if (target.includes('characteristics')) {
        const characteristicsCard = section.querySelector('.js-characteristics')
        const content = characteristicsCard.querySelector('.js-collapse-content')
        const textLess = characteristicsCard.querySelector('.js-collapse-less')
        const textMore = characteristicsCard.querySelector('.js-collapse-more')
        const icon = characteristicsCard.querySelector('.js-collapse-icon')

        content.classList.add('is-active')
        icon.classList.add('is-rotate')
        textLess.classList.remove('is-hidden')
        textMore.classList.add('is-hidden')
      }

      window.scrollTo({ top: totalOffset })
    }

    items.forEach(item => {
      item.addEventListener('click', () => {
        clickHandler(item)
      })
    })

    setTimeout(() => {
      if (windowHash) {
        const item = items.find(el => el.getAttribute('data-target').includes(windowHash))

        if (!item) return

        clickHandler(item)

        window.history.replaceState(null, null, window.location.pathname)
      }
    }, 1000)
  },
})
