<template lang="pug">
  include ../mixins
  +b.LABEL.control-label.--variant_bound(
    :class="{ 'is-active': hasValue || isBound }"
  )
    +b.g-row.--space_xs.--align_center
      +b.g-cell
        span {{ inputLabel }}
        span(v-if='isRequired')  *
      //- +b.g-cell(v-if="hint")
      //-   +b.hint(v-tooltip.auto="{content: hint, trigger: 'click hover'}")
      //-     +e.icon
      //-       icon-info
</template>

<script>
import { isEmpty } from 'lodash'

export default {
  props: {
    id: {
      type: String,
      required: false,
    },
    value: {},
    inputLabel: {
      type: String,
    },
    hint: {
      type: String,
    },
    isRequired: {
      type: Boolean,
    },
    isBound: {
      type: Boolean,
    },
  },

  computed: {
    hasValue() {
      return !isEmpty(this.value)
    },
  },
}
</script>
