<template lang="pug">
include ../mixins
swiper(:options="swiperOptions")
  slot
</template>

<script>
export default {
  props: {
    prevEl: {
      type: String,
    },
    nextEl: {
      type: String,
    },
    paginationEl: {
      type: String,
    },
  },

  data() {
    return {
      swiperOptions: {
        autoHeight: true,
        spaceBetween: 0,
        navigation: {
          prevEl: this.prevEl,
          nextEl: this.nextEl,
        },
        pagination: {
          el: this.paginationEl,
          clickable: true,
        },
        slidesPerView: 4,
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
        },
      },
    }
  },
}
</script>
