<script>
import { turnFavoriteResource } from '@services/actions.service.js'
import { mapState, mapMutations } from 'vuex'
import Forbidden from '@app/Modals/Authentication/Forbidden'

export default {
  props: {
    itemId: {},
    isActive: {},
    objectItem: Object,
  },

  data() {
    return {
      isActiveProduct: this.isActive,
      isAuth: window.isAuthenticated,
    }
  },

  computed: {
    ...mapState('favorite', ['lastAction']),
  },

  watch: {
    lastAction: {
      deep: true,
      handler(nval) {
        if (Number(this.itemId) === Number(nval.added)) {
          this.isActiveProduct = true
        }

        if (Number(this.itemId) === Number(nval.removed)) {
          this.isActiveProduct = false
        }
      },
    },
  },

  methods: {
    ...mapMutations('favorite', [
      'UPDATE_COUNTER',
      'SET_REMOVED_ID',
      'SET_ADDED_ID',
    ]),

    handleClick() {
      if (this.isAuth) {
        this.send()
      }
    },

    send() {
      const formdata = { id_product: this.itemId }

      turnFavoriteResource.execute({}, formdata).then(res => {
        const { data: { item } } = res

        this.isActiveProduct = item.create

        if (this.isActiveProduct) {
          this.SET_ADDED_ID(this.itemId)
          this.SET_REMOVED_ID(null)
        } else {
          this.SET_REMOVED_ID(this.itemId)
          this.SET_ADDED_ID(null)
        }

        this.UPDATE_COUNTER(item.count)

        this.showNotification()

        // emit to product card template
        this.$emit('update', this.itemId)
      })
    },

    showNotification() {
      if (this.isActiveProduct) {
        this.$notify({
          title: this._('Додано в обране'),
          type: 'success',
        })
      } else {
        this.$notify({
          title: this._('Видалено з обраного'),
          type: 'success',
        })
      }
    },

    handleAuth() {
      this.$modal.show(Forbidden, {
        info: {
          title: this._('Лайкати можуть лише авторизовані користувачі'),
        },
      })
    },
  },
  render() {
    return this.$scopedSlots.default({
      action: this.handleClick,
      isActive: this.isActiveProduct,
    })
  },
}
</script>
