import Vue from 'vue'

Vue.directive('category-navigation', {
  bind: node => {
    const togglers = [...node.querySelectorAll('.js-catalog-toggler')]
    const navigation = node.querySelector('.js-navigation')
    const blur = node.querySelector('.js-navigation-blur')
    const close = node.querySelector('.js-navigation-close')
    const sidebar = node.querySelector('.js-sidebar')
    const sidebarBlur = node.querySelector('.js-sidebar-blur')

    togglers.forEach(item => {
      item.addEventListener('click', () => {
        const IS_DESKTOP = 1200 <= window.innerWidth

        if (!IS_DESKTOP) return

        navigation.classList.toggle('is-active')
        blur.classList.toggle('is-active')

        sidebar.classList.remove('is-active')
        sidebarBlur.classList.remove('is-active')
      })
    })

    blur.addEventListener('click', () => {
      navigation.classList.remove('is-active')
      blur.classList.remove('is-active')
    })

    close.addEventListener('click', () => {
      navigation.classList.remove('is-active')
      blur.classList.remove('is-active')
    })
  },
})
