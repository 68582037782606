<script>
export default {
  props: {
    initialActive: {
      type: [String, Number],
    },
  },

  data() {
    return {
      currentItem: this.initialActive || null,
    }
  },

  methods: {
    open(index) {
      this.currentItem = index
    },
  },

  render() {
    return this.$scopedSlots.default({
      open: this.open,
      currentItem: this.currentItem,
    })
  },
}
</script>
