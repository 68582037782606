<template lang="pug">
  include ../mixins
  +b.LABEL.i-switch(:class="{ 'is-disabled': disabled }")
    +e.content
      +e.INPUT.element(
        v-model="localValue",
        type="checkbox",
        name="availability",
        value="available",
        @change="emitValue"
      )
      +e.SPAN.slider
</template>

<script>
export default {
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      localValue: false,
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(nVal) {
        this.localValue = nVal
      },
    },
  },

  methods: {
    emitValue() {
      this.$emit('input', this.localValue)
    },
  },
}
</script>
