<template lang="pug">
include ../mixins
div.relative
  ui-preloader(v-if="isLoad")
  +b.g-row.--appearance_spaced.--space_xl-xl
    +b.g-cell.g-cols.--12-xs
      +b.c-card
        +b.g-row.--appearance_spaced.--space_4xl-xl
          +b.g-cell.g-cols--12.--8-sm
            +b.control-icon-wrapper
              +e.icon.--stroke_relief-4
                icon-search
              +e.field
                control-autocomplete(
                  ref="autocomplete"
                  v-model="productQuery"
                  :filter="productAutocomplete"
                  :emit-full-value="true"
                  @search="productSearchHandler"
                )
          +b.g-cell.g-cols--12.--4-sm
            +b.BUTTON.control-button(
              @click.prevent="searchProduct"
            )
              +e.element
                +e.SPAN.content {{ _("Додати") }}
    +b.g-cell.g-cols.--12-xs
      +b.c-card
        export-content-products(
          :formdata="formdata"
          @item:delete="itemDelete"
        )
    +b.g-cell.g-cols.--12-xs
      +b.c-card
        +b.g-row.--appearance_spaced.--space_3xl.--space_1
          +b.g-cell.g-cols--12.--6-md
            export-content-file-format(
              :items="choices.fileFormat"
              :formdata="formdata"
            )
          +b.g-cell.g-cols--12.--6-md
            export-content-language(
              :items="choices.fileLanguage"
              :formdata="formdata"
            )
    +b.g-cell.g-cols.--12-xs
      +b.c-card
        export-content-additional(
          :items="choices.additional"
          :formdata="formdata"
        )
    +b.g-cell.g-cols.--12-xs
      +b.ds-panel.--space_sm.--space_none-lg
        +e.element.--offset_top
          +b.g-row.--justify_end
            +b.g-cell.g-cols.--12-xs.--narrow-sm
              +b.BUTTON.control-button(
                @click.prevent="validateBeforeSubmit"
                :disabled="isLoad"
              )
                +e.element
                  +e.SPAN.content {{ _("Завантажити") }}
</template>

<script>
import {
  contentPayloadsResource,
  contentExportProductsResource,
} from '@services/content.service.js'
import { CONTENT } from '@services/paths'
import {
  openConfirmModal,
  openMessageModal,
} from '@utils/submit'
import exportFile from '@utils/exportFile'

export default {
  components: {
    'export-content-additional': () => import('@app/ExportContent/components/Additional'),
    'export-content-file-format': () => import('@app/ExportContent/components/FileFormat'),
    'export-content-language': () => import('@app/ExportContent/components/Language'),
    'export-content-products': () => import('@app/ExportContent/components/Products'),
  },

  data() {
    return {
      isLoad: false,
      isProductSearchLoad: false,
      productQuery: null,
      formdata: {
        additional: [],
        products: [],
        fileLanguage: null,
        fileFormat: null,
      },
      choices: {
        fileFormat: [],
        fileLanguage: [],
        additional: [],
      },
      productAutocomplete: {
        name: 'product',
        meta: {
          url: CONTENT.PRODUCT_AUTOCOMPLETE,
          title: 'label',
          query_param: 'search',
          placeholder: this._('Артікл / Назва'),
          value: 'id',
        },
      },
    }
  },

  async created() {
    await this.getPayloads()

    this.preselectData()
  },

  methods: {
    async getPayloads() {
      const { data: { item } } = await contentPayloadsResource.execute()

      this.choices.fileFormat = item.fileFormat.choices
      this.choices.fileLanguage = item.fileLanguage.choices
      this.choices.additional = item.additional.choices
    },

    preselectData() {
      this.formdata.fileFormat = this.choices.fileFormat[0].value
      this.formdata.fileLanguage = this.choices.fileLanguage[0].value

      this.choices.additional.forEach(item => {
        this.formdata.additional.push(item.value)
      })
    },

    async itemDelete(productId) {
      const title = this._('Ви впевнені, що хочете видалити цей товар?')

      await openConfirmModal({ title }, this)

      this.formdata.products = this.formdata.products.filter(p => p.id !== productId)
    },

    productSearchHandler(state) {
      this.isProductSearchLoad = state
    },

    searchProduct() {
      if (!this.productQuery) {
        const title = this._('Оберіть товар')

        openMessageModal({ title }, this)

        return
      }

      const alreadyAdded = this.formdata.products.find(el => el.id === this.productQuery.id)

      if (alreadyAdded) {
        const title = this._('Цей товар вже додано до списку')

        openMessageModal({ title }, this)

        return
      }

      this.formdata.products.unshift(this.productQuery)

      this.clearAutocomplete()
    },

    clearAutocomplete() {
      this.productQuery = null

      this.$refs.autocomplete.clearValue()
    },

    validateBeforeSubmit() {
      const hasProducts = this.formdata.products.length
      const hasAdditional = this.formdata.additional.length

      if (!hasProducts) {
        const title = this._('Додайте хоча б один товар')

        openMessageModal({ title }, this)

        return
      }

      if (!hasAdditional) {
        const title = this._('Оберіть хоча б одну додаткову опцію')

        openMessageModal({ title }, this)

        return
      }

      this.send()
    },

    prepareFormdata() {
      const formdata = JSON.parse(JSON.stringify(this.formdata))

      formdata.products = formdata.products.map(item => item.id)

      return formdata
    },

    send() {
      if (this.isLoad || this.isProductSearchLoad) {
        return
      }

      const formdata = this.prepareFormdata()

      this.isLoad = true

      exportFile(contentExportProductsResource, formdata).then(() => {
      }).finally(() => {
        this.isLoad = false
      })
    },
  },
}
</script>
