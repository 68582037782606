import { withDefaultProps } from '@aspectus/vue-utils'
import { Descriptor, NoneController } from '@aspectus/vee-control-descriptor'

import UnifiedControlElement from './UnifiedControlElement'
import ErrorComponent from './ErrorComponent'

import Multiselect from './Multiselect'
import Autocomplete from './Autocomplete'

import BoundLabel from './BoundLabel'

const composerForInput = (input, label = BoundLabel) => withDefaultProps({
  labelComponent: () => label,
  element: () => input,
})(UnifiedControlElement)

const composer = input => withDefaultProps({
  element: () => input,
  labelComponent: null,
  errorComponent: () => ErrorComponent,
  controllerComponent: () => NoneController,
  bails: true,
})(Descriptor)

// input
const InputWithLabel = composerForInput('Input')
const ControlInput = composer(InputWithLabel)

// input without label
const PureInput = composerForInput('Input', null, null)
const ControlPureInput = composer(PureInput)

// multiselect
const MultiselectWithLabel = composerForInput(Multiselect)
const ControlMultiselect = composer(MultiselectWithLabel)

// textarea
const TextareaWithLabel = composerForInput('Textarea')
const ControlTextarea = composer(TextareaWithLabel)

export default function install(Vue, { prefix = 'DControl' } = {}) {
  Vue.component(`${prefix}Input`, ControlInput)
  Vue.component(`${prefix}PureInput`, ControlPureInput)
  Vue.component(`${prefix}Textarea`, ControlTextarea)
  Vue.component(`${prefix}Multiselect`, ControlMultiselect)
  Vue.component('Multiselect', Multiselect)
  Vue.component('ControlAutocomplete', Autocomplete)
}
