import CardSearch from './CardSearch'
import CardCompare from './CardCompare'
import CardWrapper from './CardWrapper'
import CardSmall from './CardSmall'
import Gallery from './Gallery'
import GalleryLarge from './GalleryLarge'
import Delivery from './Delivery'

import Review from './Review'

export default function install(Vue) {
  Vue.component('product-card-search', CardSearch)
  Vue.component('product-card-compare', CardCompare)
  Vue.component('product-card-wrapper', CardWrapper)
  Vue.component('product-card-small', CardSmall)
  Vue.component('product-gallery', Gallery)
  Vue.component('product-gallery-large', GalleryLarge)
  Vue.component('product-delivery', Delivery)

  Vue.use(Review)
}
