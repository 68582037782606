import { cartCountResource } from '@services/cart.service.js'

export const cart = {
  namespaced: true,

  state: {
    cartCount: {},
    cart: {},
  },

  actions: {
    getCartCount({ commit }) {
      return cartCountResource.execute().then(res => {
        const { data: { item } } = res

        commit('CHANGE_CART_COUNT', item)
      })
    },
  },

  mutations: {
    CHANGE_CART_COUNT(state, payload) {
      state.cartCount = payload
    },

    CHANGE_CART_STATE(state, payload) {
      state.cart = payload
    },
  },
}
