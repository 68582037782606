const iconsComp = require.context('@/js/components/Icons/', true, /\.vue$/)

/* eslint-disable no-restricted-syntax */
/* eslint-disable func-names */

export default function (app) {
  for (const comp of iconsComp.keys()) {
    const name = comp.replace(/^\.\//, '').replace(/\.\w+$/, '')

    app.component(`Icon${name}`, () => import(`@/js/components/Icons/${name}.vue`))
  }
}
