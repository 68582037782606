import Vuex from 'vuex'

import { user } from './modules/user.store.js'
import { cart } from './modules/cart.store.js'
import { compare } from './modules/compare.store.js'
import { favorite } from './modules/favorite.store.js'

export function createStore() {
  return new Vuex.Store({
    deep: true,
    modules: {
      user,
      cart,
      compare,
      favorite,
    },
  })
}
