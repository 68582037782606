import {
  postResource,
  createResource,
  getResource,
} from '@resource/resource'
import { CONFIGURATOR } from './paths'

export const configuratorDetailResource = createResource(
  CONFIGURATOR.CONFIGURATOR_DETAIL, getResource
)

export const turnConfiguratorResource = createResource(
  CONFIGURATOR.CONFIGURATOR_ADD, postResource
)

export const configuratorSelectResource = createResource(
  CONFIGURATOR.CONFIGURATOR_SELECT, postResource
)

export const configuratorDeleteGroupResource = createResource(
  CONFIGURATOR.CONFIGURATOR_DELETE_GROUP, postResource
)
