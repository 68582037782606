import {
  postResource,
  createResource,
} from '@resource/resource'
import { DELIVERY } from './paths'

export const deliveryCityChangeResource = createResource(
  DELIVERY.CITY_CHANGE, postResource
)

export const deliveryCityListMiniResource = createResource(
  DELIVERY.CITY_LIST_MINI
)

export const deliveryCitySearchResource = createResource(
  DELIVERY.CITY_SEARCH
)

export const deliveryMethodsResource = createResource(
  DELIVERY.METHODS, postResource
)

export const deliveryCostCalculateResource = createResource(
  DELIVERY.COST_CALCULATE, postResource
)
