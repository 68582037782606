import Vue from 'vue'

Vue.directive('collapse', {
  bind: (node, arg) => {
    const { heights, rows } = arg.value

    const button = node.querySelector('.js-collapse-button')
    const content = node.querySelector('.js-collapse-content')
    const textLess = node.querySelector('.js-collapse-less')
    const textMore = node.querySelector('.js-collapse-more')
    const icon = node.querySelector('.js-collapse-icon')
    const htmlEl = document.querySelector('html')

    const contentRows = [...content.querySelectorAll('tr')]

    const getContentCollapsedHeight = () => {
      if (rows) {
        if (rows >= contentRows.length) {
          return null
        }

        const slicedItems = contentRows.slice(0, rows)
        const totalHeightOfSlicedItems = slicedItems.reduce((acc, el) => {
          acc += el.clientHeight

          return acc
        }, 0)

        return totalHeightOfSlicedItems
      }

      const screenWidth = Object.keys(heights)
      const height = Object.values(heights)

      for (let i = 0; i < screenWidth.length; i++) {
        if (window.matchMedia(`(max-width: ${screenWidth[i]}px)`).matches) {
          return height[i]
        }
      }

      return 0
    }

    const showButton = () => {
      const contentHeight = content.scrollHeight
      const contentCollapsedHeight = getContentCollapsedHeight()

      if (contentCollapsedHeight && contentHeight > contentCollapsedHeight) {
        content.style.height = `${contentCollapsedHeight}px`

        button.classList.add('is-active')
        button.classList.remove('is-hidden')
      }
    }

    setTimeout(() => {
      showButton()
    }, 100)

    button.addEventListener('click', () => {
      content.classList.toggle('is-active')
      icon.classList.toggle('is-rotate')
      textLess.classList.toggle('is-hidden')
      textMore.classList.toggle('is-hidden')

      if (!content.classList.contains('is-active')) {
        const headerHeight = document.querySelector('.header').clientHeight
        const navigationHeight = document.querySelector('.product-navigation').clientHeight
        const wrapper = content.closest('.js-collapse-wrapper')
        const y = wrapper.getBoundingClientRect().top + window.pageYOffset - headerHeight - navigationHeight

        htmlEl.classList.add('disable-smooth')

        window.scrollTo({ top: y })

        htmlEl.classList.remove('disable-smooth')
      }
    })
  },
})
