import {
  postResource,
  createResource,
} from '@resource/resource'
import { TWO_FACTOR } from './paths'

export const twoFactorRequestConfirmationResource = createResource(
  TWO_FACTOR.REQUEST_CONFIRMATION, postResource
)
export const twoFactorConfirmResource = createResource(
  TWO_FACTOR.CONFIRM, postResource
)
